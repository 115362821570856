/* Default Layout related */
.ff-richtext,
.progress-box-v1 .progress-title,
.progress-box-v2 .progress-title,
.rating-container .caption>.label,
.zeus .tp-bullet-title,
a,
body,
h1,
h2,
h3,
h4,
h5,
h6,
li,
li a,
p {
    font-family: Rubik, Arial, sans-serif
}

.home .ffb-id-pfeamtm,
.home .ffb-id-pfeamu1 {
    padding: 0 !important
}

.home h3 {
    margin-top: 0 !important
}

.home .blog-grid-title-lg {
    display: none
}

.mb-0 {
    margin-bottom: 0
}

.mb-10 {
    margin-bottom: 10px
}

.mb-15 {
    margin-bottom: 15px
}

.mb-20 {
    margin-bottom: 20px
}

.my-25 {
    margin-top: 25px;
    margin-bottom: 25px
}

.my-30 {
    margin-top: 30px;
    margin-bottom: 30px
}

.my-50 {
    margin-top: 50px;
    margin-bottom: 50px
}

.pt-50 {
    padding-top: 50px
}

.text-white {
    color: #fff !important
}

.page .wrapper .page-wrapper sup,
sup {
    position: relative;
    font-size: .6em !important;
    line-height: 1 !important;
    vertical-align: .3em;
    font-style: normal
}

.wave-bg {
    width: 100%;
    height: 220px;
    margin-top: -210px;
    pointer-events: none
}

.wave-bg .wave-animation {
    width: 100%;
    height: 220px
}

.ffb-admin-console {
    display: none
}

.ffb-id-navigation-header.animsition {
    z-index: 1000
}

#Line_1 {
    animation: pulse .5s infinite;
    animation-delay: .15s
}

#Line_2 {
    animation: pulse .5s infinite;
    animation-delay: .3s
}

#Line_3 {
    animation: pulse .5s infinite;
    animation-delay: .45s
}

#Line_4 {
    animation: pulse .5s infinite;
    animation-delay: .6s
}

#Line_5 {
    animation: pulse .5s infinite;
    animation-delay: .75s
}

#Line_6 {
    animation: pulse .5s infinite;
    animation-delay: .9s
}

#Line_7 {
    animation: pulse .5s infinite;
    animation-delay: 1.05s
}

#Line_8 {
    animation: pulse .5s infinite;
    animation-delay: 1.2s
}

#Line_9 {
    animation: pulse .5s infinite;
    animation-delay: 1.35s
}

@keyframes pulse {
    0% {
        transform: scaleY(1);
        transform-origin: 50% 50%
    }

    50% {
        transform: scaleY(.7);
        transform-origin: 50% 50%
    }

    100% {
        transform: scaleY(1);
        transform-origin: 50% 50%
    }
}

.collection-bg {
    position: relative
}

.collection-bg:before {
    position: absolute;
    z-index: -1;
    top: -124px;
    width: 100%;
    height: 723px;
    content: '';
    background: url(https://d1es8luhkr7rvi.cloudfront.net/uploads/2017/06/collection-bg.svg) top center;
    background-size: auto 100%
}

.feedback-bg {
    position: relative;
    min-height: 840px;
    background: #f7f7f7
}

.feedback-bg:after {
    position: absolute;
    bottom: 25px;
    width: 100%;
    height: 529px;
    content: '';
    background: url(https://d1es8luhkr7rvi.cloudfront.net/uploads/2017/06/feedback-bg.svg) bottom center;
    background-size: auto 100%
}

.feedback-bg .fg-container {
    padding: 0 !important
}

.section-heading h2,
.section-heading h2:hover {
    font-size: 32px !important;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 0 !important;
    text-align: center;
    color: #364c69 !important
}

.section-heading h2+p,
.section-heading h2:hover+p {
    color: rgba(54, 76, 105, .7) !important
}

.section-heading h3,
.section-heading h3:hover {
    font-size: 28px !important;
    font-weight: 400;
    line-height: 1.3;
    margin-top: 0 !important;
    text-align: center;
    color: #364c69 !important
}

.section-heading h3+p,
.section-heading h3:hover+p {
    color: rgba(54, 76, 105, .7) !important
}

.section-heading h4,
.section-heading h4:hover {
    font-size: 24px !important;
    font-weight: 400;
    line-height: 1.17;
    margin-top: 0 !important;
    text-align: center;
    color: #364c69 !important
}

.section-heading h4+p,
.section-heading h4:hover+p {
    color: rgba(54, 76, 105, .7) !important
}

::-moz-selection {
    color: #fff !important;
    background: #ac74fe !important;
    text-shadow: none
}

::selection {
    color: #fff !important;
    background: #ac74fe !important;
    text-shadow: none
}

.ff-logo-grid .row {
    width: 100%;
    text-align: center;
    background: 0 0
}

.ff-logo-grid .col {
    display: inline-block !important;
    float: none !important;
    width: auto !important
}

.ffb-id-pfeamtm {
    padding-top: 70px;
    padding-bottom: 70px
}

.exp-owl.no-overflow .owl-item {
    overflow: initial !important
}

.exp-owl .owl-item .expire-action-btn {
    box-shadow: none
}

.owl-item .item .testimonials-v1 {
    position: relative;
    z-index: 1;
    float: left;
    min-height: 340px;
    margin: 0;
    padding: 34px 36px 21px 30px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 12px 23px 0 rgba(97, 75, 146, .03), 0 2px 17px 0 rgba(0, 0, 0, .04)
}

.owl-item .item .testimonials-v1 h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 56px;
    overflow: hidden;
    width: 100%;
    height: 56px;
    margin-bottom: 0 !important;
    padding: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #364c69
}

.owl-item .item .testimonials-v1 p {
    font-size: 14px !important;
    line-height: 1.71;
    float: left;
    width: 100%;
    letter-spacing: -.1px;
    color: rgba(54, 76, 105, .7)
}

.table-content {
    font-size: 13px;
    border-collapse: collapse;
    color: #364c69;
    border: 1px solid #e5e5e5
}

.table-content td,
.table-content th {
    min-width: 60px;
    padding: 10px;
    border: 1px solid #e5e5e5
}

.woocommerce-product-details__short-description blockquote {
    margin: 15px 0;
    padding: 5px 20px;
    border: 0;
    background: #fff
}

.woocommerce-product-details__short-description blockquote ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.woocommerce-product-details__short-description blockquote ul li {
    font-weight: 500;
    line-height: 30px;
    padding: 7px 2px;
    color: #364c69;
    border-bottom: 1px solid #e5e5e5
}

.woocommerce-product-details__short-description blockquote ul li:before {
    display: inline-block;
    width: 7px;
    height: 10px;
    margin-right: 20px;
    content: '';
    background: url(./images/list.svg) no-repeat left center
}

.woocommerce-product-details__short-description blockquote ul li:last-child {
    border-bottom: 0
}

.mfp-iframe-holder .mfp-close {
    z-index: 999999;
    right: 0
}

.owl-carousel .owl-stage {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab
}

.btn.btn-secondary.btn-md img.svg-picture,
.btn.btn-secondary.btn-md img.svg-pic {
    -webkit-filter: invert(48%) sepia(71%) saturate(707%) hue-rotate(208deg) brightness(103%) contrast(98%);
    filter: invert(48%) sepia(71%) saturate(707%) hue-rotate(208deg) brightness(103%) contrast(98%)
}

/* Homepage related */
.home .home_carousel h3 {
    font-size: 32px !important;
    font-weight: 400;
    line-height: 1.5
}

.custom-video-frame {
    margin-top: 50px
}

.custom-video-frame iframe {
    width: 100%;
    height: 320px
}

.btn.left-icon span.btn-text img {
    line-height: 40px;
    margin-right: 16px;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-filter: invert(1);
    filter: invert(1);
    -ms-flex-order: -5;
    order: -5
}

.fade.show {
    opacity: 1
}

.modal.show .modal-dialog {
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
}

#emailCaptureModal .modal-dialog .modal-content svg {
    margin-bottom: 16px
}

/* Woocommerce related */
.woocommerce-breadcrumb {
    font-size: 14px;
    margin: 0;
    padding: 10px 0;
    color: #364c69;
    background: 0 0
}

.woocommerce-breadcrumb a {
    text-decoration: none;
    opacity: .7;
    color: #364c69
}

.woocommerce-breadcrumb a:hover {
    opacity: 1
}

.woocommerce-breadcrumb svg {
    margin: 0 10px;
    display: inline-block;
}

.woocommerce-product-gallery__wrapper {
    float: none;
    width: 100%;
    text-align: center
}

.woocommerce-product-gallery__wrapper img {
    display: inline;
    max-width: 310px;
    height: calc(100vh - 435px);
    min-height: 180px;
    max-height: 310px;
    border-radius: 6px
}

.product-image-section {
    position: relative;
    z-index: 2;
    padding-left: 0
}

.product-image-section h2 {
    font-size: 24px;
    font-weight: 500;
    margin-top: 20px !important;
    margin-bottom: 10px;
    text-align: center;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: #3a4485
}

.product-image-section h4 {
    text-align: center
}

.product-image-section h4 a {
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0 20px 0;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #6f6f6f
}

.product-image-section .woocommerce-variation-availability .stock {
    position: absolute;
    top: -9999px;
    opacity: 0
}

.product-image-section .cart,
.product-image-section .woocommerce-variation-add-to-cart {
    margin-top: 24px;
    margin-bottom: 24px !important;
    text-align: center
}

.product-image-section .cart .quantity,
.product-image-section .woocommerce-variation-add-to-cart .quantity {
    display: inline-block;
    float: none !important;
    margin: 0 10px 0 0 !important;
    vertical-align: middle
}

.product-image-section .cart .quantity input[type=number],
.product-image-section .woocommerce-variation-add-to-cart .quantity input[type=number] {
    font-size: 18px !important;
    height: 56px !important;
    border: solid 1px #e5e5e5;
    border-radius: 2px
}

.product-image-section .cart .quick_buy_container,
.product-image-section .woocommerce-variation-add-to-cart .quick_buy_container {
    display: inline-block;
    float: none;
    margin: 0 10px;
    vertical-align: middle
}

.product-image-section .cart .btn-secondary,
.product-image-section .woocommerce-variation-add-to-cart .btn-secondary {
    display: inline-block;
    float: none;
    margin: 0 0 0 10px;
    vertical-align: middle
}

.product-summary-section {
    padding-right: 0
}

.product-summary-section .product-summary iframe {
    width: 100%;
    height: auto;
    min-height: 320px
}

.product-summary-section .product-summary.border-top>div,
.product-summary-section .product-summary.border-top>p {
    padding-top: 16px;
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.product-summary-section .product-summary.border-top>div+p,
.product-summary-section .product-summary.border-top>p+p {
    padding-bottom: 16px;
    border-top: 0
}

.product-summary-section .product-summary h1 {
    font-size: 32px !important;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
    color: #364c69
}

.product-summary-section .product-summary h3 {
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    margin: 0;
    color: #364c69
}

.product-summary-section .product-summary h4:not(.share-title) {
    font-size: 24px;
    line-height: 2;
    color: #364c69
}

.product-summary-section .product-summary p {
    margin: 10px 0
}

.product-summary-section .product-summary figure {
    overflow: hidden;
    margin: 10px 0 24px
}

.product-summary-section .product-summary figure .rll-youtube-player img {
    width: 100%
}

.product-summary-section .product-summary figure img {
    float: left;
    width: 100px;
    border-radius: 4px
}

.product-summary-section .product-summary figure figcaption {
    display: inline-block;
    float: right;
    width: calc(100% - 100px);
    padding-left: 20px
}

.product-summary-section .product-summary .description-wrapper {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: -.1px;
    color: rgba(54, 76, 105, .7)
}

.product-summary-section .product-summary .learn-more {
    margin-top: 24px
}

.product-summary-section .product-summary .product_meta {
    margin-top: 20px
}

.product-summary-section .product-summary .product_meta .sku_wrapper,
.product-summary-section .product-summary .product_meta .tagged_as {
    font-size: 14px;
    line-height: 1.7;
    display: inline-block;
    width: 35%;
    color: rgba(54, 76, 105, .7)
}

.product-summary-section .product-summary .product_meta .sku_wrapper .sku,
.product-summary-section .product-summary .product_meta .sku_wrapper .stock-info,
.product-summary-section .product-summary .product_meta .sku_wrapper a,
.product-summary-section .product-summary .product_meta .tagged_as .sku,
.product-summary-section .product-summary .product_meta .tagged_as .stock-info,
.product-summary-section .product-summary .product_meta .tagged_as a {
    font-size: 18px !important;
    font-weight: 500;
    line-height: 1.33;
    display: block;
    margin: 5px 0 0 !important;
    color: #364c69 !important
}

.product-summary-section .product-summary .product_meta .posted_in {
    display: none
}

.for-audio {
    display: none
}

.product_tag-free .divider,
.product_tag-free .quantity,
.product_tag-free .quick_buy_container {
    display: none !important
}

.downloadable.virtual.product-type-simple .wcmp-player-container~.for-audio {
    display: block
}

.wcmp-player-container~form .for-audio {
    display: block
}

.stock {
    display: none !important
}

.product-type-variable .quantity,
.product-type-variable .stock {
    display: none !important
}

.product-type-variable[data-varsku=shipping] .quantity,
.product-type-variable[data-varsku=shipping] .stock {
    display: inline-block !important
}

.woocommerce-product-gallery__trigger {
    display: none
}

.woocommerce-orders-table .btn-link-icon.cancel,
.woocommerce-orders-table .btn-link-icon.pay {
    display: none
}

.woocommerce-mini-cart-wrapper .shopping-cart-menu {
    position: relative;
    width: 320px !important;
    max-height: 520px;
    padding-bottom: 100px;
    border-top: 1px solid #e5e5e5 !important;
    box-shadow: 2px 2px 7px rgba(0, 0, 0, .3)
}

.woocommerce-mini-cart-wrapper .shopping-cart-menu ul {
    overflow: auto;
    max-height: 400px;
    margin: 0 0 5px 0 !important
}

.woocommerce-mini-cart-wrapper .shopping-cart-menu-title {
    display: none
}

.woocommerce-mini-cart-wrapper .mini_cart_item {
    padding: 10px
}

.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-menu-product-media a {
    height: inherit;
    padding: 0
}

.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-menu-product-media a img {
    border-radius: 4px
}

.woocommerce-mini-cart-wrapper .mini_cart_item:last-child {
    border-bottom: 0
}

.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-menu-product-wrap a,
.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-menu-product-wrap a:hover {
    font-size: 14px;
    font-weight: 500;
    height: inherit;
    padding: 0;
    text-decoration: none;
    color: #364c69 !important
}

.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-menu-product-wrap a .shopping-cart-menu-product-name,
.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-menu-product-wrap a:hover .shopping-cart-menu-product-name {
    color: #364c69 !important
}

.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-menu-product-wrap a .type,
.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-menu-product-wrap a:hover .type {
    font-weight: 400;
    color: #364c69 !important
}

.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-menu-product-wrap .shopping-cart-menu-product-price {
    font-size: 14px;
    color: #7235fd
}

.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-close,
.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-close:not(:hover) {
    width: 20px;
    height: 20px;
    margin: 20px 0;
    opacity: .7;
    color: transparent !important;
    background: url(./images/close.svg) no-repeat;
    background-color: none !important
}

.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-close:hover,
.woocommerce-mini-cart-wrapper .mini_cart_item .shopping-cart-close:not(:hover):hover {
    opacity: 1;
    color: transparent !important;
    background: url(./images/close.svg) no-repeat;
    background-color: none !important
}

.woocommerce-mini-cart-wrapper .shopping-cart-subtotal {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    padding: 15px 15px 15px 15px;
    border-top: 1px solid #e5e5e5;
    background: #f7f7f7
}

.woocommerce-mini-cart-wrapper .shopping-cart-subtotal .shopping-cart-subtotal-content {
    font-weight: 500
}

.woocommerce-mini-cart-wrapper .shopping-cart-subtotal .shopping-cart-subtotal-content .shopping-cart-subtotal-price {
    color: #7235fd
}

.woocommerce-mini-cart-wrapper .shopping-cart-subtotal .shopping-cart-subtotal-checkout-link {
    display: inline-block;
    float: right;
    width: 48%
}

.woocommerce-mini-cart-wrapper .shopping-cart-subtotal .shopping-cart-subtotal-view {
    display: inline-block;
    width: 48%;
    margin-right: 9px
}

.woocommerce-mini-cart-wrapper .shopping-cart-subtotal .shopping-cart-subtotal-view .shopping-cart-subtotal-view-link {
    width: 100%
}

.single-product .hs-product-section {
    z-index: 2
}

.single-product .product-image-section span.onsale {
    font-size: 22px;
    font-weight: 500;
    line-height: 66px;
    top: -15px;
    left: 95px;
    min-width: 72px;
    min-height: 72px;
    background-image: linear-gradient(rgba(0, 0, 0, .01), rgba(0, 0, 0, .01)), linear-gradient(285deg, #549ff8, #ba6eff);
    box-shadow: 0 7px 33px 0 rgba(130, 77, 191, .19)
}

.single-product .product-summary-section .woocommerce-product-details__short-description p img.size-full {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 10px !important
}

.single-product .product-summary-section .product-summary.border-top table {
    width: 100%;
    margin: 10px auto 20px;
    text-align: center;
    border: none
}

.single-product .product-summary-section .product-summary.border-top .content-block-v1 {
    padding: 16px 0
}

.single-product .product-summary-section .product-summary.border-top .content-block-v1 img.size-full {
    display: block;
    width: 100%;
    height: auto
}

.single-product tr.wcmp-odd-row:first-child .wcmp-player-container {
    z-index: 10000
}

.wcmp-odd-row td {
    border: 0 !important;
    background: 0 0 !important
}

.hemi-lesson-player-container,
.wcmp-player-container {
    position: fixed;
    z-index: 9999;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 87px
}

.hemi-lesson-player-container .audio-info,
.wcmp-player-container .audio-info {
    position: absolute;
    z-index: 1;
    top: 3px;
    left: 0;
    padding: 15px 20px 12px
}

.hemi-lesson-player-container .audio-info img,
.wcmp-player-container .audio-info img {
    float: left;
    width: inherit !important;
    height: 56px !important;
    border-radius: 4px
}

.hemi-lesson-player-container .audio-info h2,
.wcmp-player-container .audio-info h2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;
    float: left;
    margin-top: 0 !important;
    margin-left: 16px;
    letter-spacing: 0;
    text-transform: none;
    color: #333
}

.hemi-lesson-player-container .info-popover,
.wcmp-player-container .info-popover {
    position: absolute;
    z-index: 5;
    top: 38px;
    right: 50px
}

.hemi-lesson-player-container .info-popover .info-text,
.wcmp-player-container .info-popover .info-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    position: absolute;
    right: -10px;
    bottom: 30px;
    display: none;
    width: 200px;
    padding: 8px;
    color: #8e83fc;
    border: solid 1px #e5e5e5;
    border-radius: 2px;
    background-color: #fff
}

.hemi-lesson-player-container .info-popover .info-text:before,
.wcmp-player-container .info-popover .info-text:before {
    position: absolute;
    right: 13px;
    bottom: -12px;
    content: '';
    border: solid 6px #e5e5e5;
    border-color: #e5e5e5 transparent transparent
}

.hemi-lesson-player-container .info-popover .info-text:after,
.wcmp-player-container .info-popover .info-text:after {
    position: absolute;
    right: 14px;
    bottom: -10px;
    content: '';
    border: solid 5px #fff;
    border-color: #fff transparent transparent
}

.hemi-lesson-player-container .info-popover .info-icon,
.wcmp-player-container .info-popover .info-icon {
    display: block;
    width: 20px;
    height: 20px;
    opacity: .7;
    outline: 0;
    background: url(./images/info-icon.svg) no-repeat
}

.hemi-lesson-player-container .info-popover .info-icon:hover,
.wcmp-player-container .info-popover .info-icon:hover {
    opacity: 1
}

.hemi-lesson-player-container .info-popover .info-icon:hover~.info-text,
.wcmp-player-container .info-popover .info-icon:hover~.info-text {
    display: block
}

.hemi-lesson-player-container .mejs-container,
.wcmp-player-container .mejs-container {
    margin: 0
}

.hemi-lesson-player-container .mejs-container .mejs-controls,
.wcmp-player-container .mejs-container .mejs-controls {
    top: 0;
    height: 87px;
    background: #f6f6f6 !important;
    background-image: none
}

.hemi-lesson-player-container .mejs-container .mejs-controls .change_track,
.wcmp-player-container .mejs-container .mejs-controls .change_track {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 18px;
    height: 18px;
    margin-top: -9px;
    cursor: pointer;
    opacity: .7;
    background: red
}

.hemi-lesson-player-container .mejs-container .mejs-controls .change_track:hover,
.wcmp-player-container .mejs-container .mejs-controls .change_track:hover {
    opacity: 1
}

.hemi-lesson-player-container .mejs-container .mejs-controls .change_track.next,
.wcmp-player-container .mejs-container .mejs-controls .change_track.next {
    margin-left: 48px;
    background: url(./images/player-next.svg) no-repeat
}

.hemi-lesson-player-container .mejs-container .mejs-controls .change_track.prev,
.wcmp-player-container .mejs-container .mejs-controls .change_track.prev {
    margin-left: -67px;
    background: url(./images/player-prev.svg) no-repeat
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time {
    top: 42px;
    left: auto !important;
    overflow: visible;
    margin-left: 0 !important;
    padding: 0
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time span,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time span {
    font-size: 14px;
    font-weight: 300;
    color: rgba(0, 0, 0, .5)
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time.mejs-currenttime-container,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time.mejs-currenttime-container {
    right: 430px
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time.mejs-currenttime-container:after,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time.mejs-currenttime-container:after {
    font-size: 14px;
    padding-left: 2px;
    content: ' /';
    color: rgba(0, 0, 0, .5)
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time.mejs-duration-container,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time.mejs-duration-container {
    right: 390px
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time.mejs-duration-container:after,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time.mejs-duration-container:after {
    position: absolute;
    top: -17px;
    right: -32px;
    width: 1px;
    height: 40px;
    content: '';
    background: #e5e5e5
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time-rail,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time-rail {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100vw !important;
    padding: 0
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-total,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-total {
    width: 100vw !important;
    height: 4px;
    margin: 0;
    padding: 0;
    border: 0;
    background: rgba(0, 0, 0, .2)
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-handle,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-handle {
    top: 0 !important;
    width: 4px;
    height: 4px;
    border: 0;
    border-radius: 0;
    background: #8e83fc !important
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-loaded,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-loaded {
    height: 4px
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-float,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-float {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    top: -29px;
    width: 50px;
    height: 24px;
    margin-left: -23px;
    color: #8e83fc;
    border: solid 1px #e5e5e5;
    border-radius: 2px;
    background-color: #fff
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-float .mejs-time-float-current,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-float .mejs-time-float-current {
    width: 45px
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-float .mejs-time-float-corner,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-float .mejs-time-float-corner {
    top: 22px;
    left: 17px;
    border: solid 7px #e5e5e5;
    border-color: #e5e5e5 transparent transparent
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-float .mejs-time-float-corner:after,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-float .mejs-time-float-corner:after {
    position: absolute;
    top: -7px;
    left: -5px;
    content: '';
    border: solid 5px #fff;
    border-color: #fff transparent transparent
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-current,
.wcmp-player-container .mejs-container .mejs-controls .mejs-time-rail .mejs-time-current {
    width: 100%;
    height: 4px;
    border: 0;
    background-image: linear-gradient(to right, #ac74fe, #8e83fc) !important
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-playpause-button,
.wcmp-player-container .mejs-container .mejs-controls .mejs-playpause-button {
    width: 48px;
    height: 48px;
    margin: 10px 0 0 -24px
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-playpause-button button,
.wcmp-player-container .mejs-container .mejs-controls .mejs-playpause-button button {
    width: 49px;
    height: 48px;
    opacity: .7;
    outline: 0
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-playpause-button button:hover,
.wcmp-player-container .mejs-container .mejs-controls .mejs-playpause-button button:hover {
    opacity: 1
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-playpause-button.mejs-play button,
.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-playpause-button.mejs-replay button,
.wcmp-player-container .mejs-container .mejs-controls .mejs-playpause-button.mejs-play button,
.wcmp-player-container .mejs-container .mejs-controls .mejs-playpause-button.mejs-replay button {
    background: url(./images/player-play.svg) no-repeat
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-playpause-button.mejs-pause button,
.wcmp-player-container .mejs-container .mejs-controls .mejs-playpause-button.mejs-pause button {
    background: url(./images/player-pause.svg) no-repeat
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-volume-button,
.wcmp-player-container .mejs-container .mejs-controls .mejs-volume-button {
    top: 38px;
    right: 300px;
    margin: 0
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-volume-button button,
.wcmp-player-container .mejs-container .mejs-controls .mejs-volume-button button {
    width: 18px;
    height: 18px;
    opacity: .7;
    outline: 0
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-volume-button button:hover,
.wcmp-player-container .mejs-container .mejs-controls .mejs-volume-button button:hover {
    opacity: 1
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-volume-button.mejs-unmute button,
.wcmp-player-container .mejs-container .mejs-controls .mejs-volume-button.mejs-unmute button {
    background: url(./images/player-volume-mute.svg) no-repeat
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-volume-button.mejs-mute button,
.wcmp-player-container .mejs-container .mejs-controls .mejs-volume-button.mejs-mute button {
    background: url(./images/player-volume.svg) no-repeat
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-horizontal-volume-slider,
.wcmp-player-container .mejs-container .mejs-controls .mejs-horizontal-volume-slider {
    position: absolute;
    top: calc(50% - 15px);
    right: 145px;
    display: inline-block !important;
    width: 140px;
    background: 0 0
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,
.wcmp-player-container .mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-total {
    position: relative;
    width: 140px;
    height: 4px;
    background: rgba(0, 0, 0, .2)
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current,
.wcmp-player-container .mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
    height: 4px;
    background-image: linear-gradient(93deg, #ac74fe, #8e83fc)
}

.hemi-lesson-player-container .mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-handle,
.wcmp-player-container .mejs-container .mejs-controls .mejs-horizontal-volume-slider .mejs-horizontal-volume-handle {
    position: absolute;
    z-index: 1;
    top: calc(50% - 9px);
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .18)
}

.product-media {
    padding: 0;
    list-style: none
}

.product-media .woocommerce-product-gallery__image {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 10px
}

.product-media .woocommerce-product-gallery__image img {
    width: inherit;
    height: 140px;
    border-radius: 4px
}

.commentlist {
    margin: 25px 0 !important;
    padding: 0
}

.commentlist .comment_container img {
    display: none
}

.commentlist .comment_container .comment-text {
    margin: 0 !important;
    padding: 0 !important;
    border: 0 !important
}

.commentlist .comment_container .comment-text .star-rating {
    float: left;
    margin: 0 15px 10px 0 !important
}

.commentlist .comment_container .comment-text h6 {
    font-size: 15px;
    font-weight: 500;
    float: left;
    margin-bottom: 5px;
    color: #364c69
}

.commentlist .comment_container .comment-text .meta {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 1.71;
    clear: both;
    margin: 0 0 6px !important;
    color: rgba(54, 76, 105, .7) !important
}

.commentlist .comment_container .comment-text .description {
    font-size: 14px !important;
    line-height: 21px;
    color: #364c69
}

.commentlist .comment_container .comment-text .description p {
    font-size: 14px !important;
    line-height: 21px;
    margin-bottom: 10px !important;
    color: #364c69 !important
}

.star-rating {
    font-size: 18px !important;
    width: 94px !important;
    margin-top: 2px !important;
    color: #ffaa5c
}

.woocommerce-product-rating {
    margin: 10px 0 0 0 !important
}

.woocommerce-product-rating a {
    font-size: 14px;
    color: rgba(54, 76, 105, .7)
}

.woocommerce table.shop_table {
    border: 0
}

.woocommerce table.shop_table thead tr th {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71;
    padding-right: 12px !important;
    padding-left: 12px !important;
    letter-spacing: -.1px;
    color: rgba(54, 76, 105, .7);
    border-top: 0;
    border-right: 0;
    border-bottom: solid 1px #e5e5e5;
    border-left: 0;
    background: 0 0
}

.woocommerce table.shop_table tfoot tr td,
.woocommerce table.shop_table tfoot tr th {
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    padding: 0 12px;
    color: #364c69;
    border-top: 1px solid #e5e5e5;
    border-right: 0;
    border-bottom: 0;
    border-left: 0
}

.woocommerce table.shop_table tbody tr th {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.71;
    letter-spacing: -.1px;
    color: rgba(54, 76, 105, .7);
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #e5e5e5;
    border-left: 0;
    background: 0 0
}

.woocommerce table.shop_table tbody tr td {
    font-size: 16px;
    line-height: 2;
    padding: 16px 12px;
    color: #364c69;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #e5e5e5;
    border-left: 0
}

.woocommerce table.shop_table tbody tr td.product-name {
    font-weight: 500
}

.woocommerce table.shop_table tbody tr td.product-name a {
    color: #364c69 !important
}

.woocommerce table.shop_table tbody tr td.product-name img {
    width: inherit;
    height: 64px;
    margin-right: 20px;
    border-radius: 4px
}

.woocommerce table.shop_table tbody tr td .wc-item-downloads {
    padding: 0
}

.woocommerce table.shop_table tbody tr td .wc-item-downloads li {
    list-style: none !important
}

.woocommerce table.shop_table tbody tr td .wc-item-downloads li a {
    font-weight: 500;
    color: #7236fd !important
}

.woocommerce table.shop_table tbody tr td.woocommerce-orders-table__cell-order-number a {
    font-weight: 500
}

.woocommerce table.shop_table tbody tr td.download-product,
.woocommerce table.shop_table tbody tr td.woocommerce-orders-table__cell-order-status {
    font-weight: 500
}

.woocommerce table.shop_table tbody tr td.product-remove a {
    width: 20px;
    height: 20px;
    margin: 20px 0;
    opacity: .7;
    color: transparent !important;
    background: url(./images/close.svg) no-repeat
}

.woocommerce table.shop_table tbody tr td.product-remove a:hover {
    opacity: 1
}

.woocommerce table.shop_table tbody tr td.product-quantity input {
    font-size: 16px !important;
    height: 56px !important;
    border: solid 1px #e5e5e5;
    border-radius: 2px
}

.woocommerce table.shop_table tbody tr td.product-subtotal {
    font-weight: 500
}

.woocommerce table.shop_table tbody tr:first-child td,
.woocommerce table.shop_table tbody tr:first-child th {
    border-top: 0
}

.woocommerce table.shop_table tbody tr.shipping td,
.woocommerce table.shop_table tbody tr.shipping th {
    border-top: 0
}

.woocommerce table.shop_table tbody tr:last-child td,
.woocommerce table.shop_table tbody tr:last-child th {
    border-bottom: 0
}

.woocommerce table.shop_table tbody tr.order-total td,
.woocommerce table.shop_table tbody tr.order-total th {
    border-top: 0 !important;
    border-bottom: 1px solid #e5e5e5
}

abbr,
span.required {
    float: left;
    padding-right: 5px;
    text-decoration: none;
    color: #7235fd !important
}

#accordion .panel {
    border: 0;
    background: 0 0 !important;
    box-shadow: none
}

#accordion .panel .panel-heading {
    height: 64px;
    padding: 8px 32px;
    border-radius: 6px !important;
    background: #fff
}

#accordion .panel .panel-heading h3 {
    float: left
}

#accordion .panel .panel-heading h3 a {
    font-size: 21px;
    line-height: 48px;
    height: 48px;
    color: #364c69
}

#accordion .panel .panel-heading .modify-link {
    font-size: 16px;
    font-weight: 500;
    line-height: 48px;
    float: right;
    color: #7235fd
}

#accordion .panel .panel-collapse {
    padding: 20px 32px 10px;
    background: 0 0
}

#accordion .panel .panel-collapse h3 {
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: -.1px;
    color: #364c69
}

#accordion .panel .panel-collapse label {
    font-size: 14px;
    font-weight: 400;
    color: rgba(54, 76, 105, .7)
}

#accordion .panel .panel-collapse .form-row {
    margin-bottom: 10px
}

#accordion .panel .panel-collapse input[type=email],
#accordion .panel .panel-collapse input[type=tel],
#accordion .panel .panel-collapse input[type=text],
#accordion .panel .panel-collapse select {
    height: 40px !important;
    padding: 6px 10px !important;
    border-radius: 2px
}

#accordion .panel .panel-collapse input[type=email],
#accordion .panel .panel-collapse input[type=tel],
#accordion .panel .panel-collapse input[type=text],
#accordion .panel .panel-collapse select,
#accordion .panel .panel-collapse textarea {
    font-size: 16px !important;
    font-weight: 500;
    color: #364c69 !important;
    border: solid 1px #e5e5e5 !important;
    background: #fff
}

#accordion .panel .panel-collapse textarea {
    padding: 10px
}

#accordion .panel .panel-collapse#checkout-billing {
    width: 60%
}

#accordion .panel .panel-collapse #order_review {
    border-top: 0
}

#accordion .panel .panel-collapse #order_review td,
#accordion .panel .panel-collapse #order_review th {
    padding-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #e5e5e5;
    border-left: 0;
    background: 0 0
}

#accordion .panel .panel-collapse tfoot th {
    padding: 5px 0
}

#accordion .panel .panel-collapse #payment {
    background: #f7f7f7
}

.woocommerce-info,
.woocommerce-message {
    line-height: 40px;
    border-color: #7235fd
}

.woocommerce-info:before,
.woocommerce-message:before {
    color: #7235fd
}

.woocommerce-page .woocommerce-info,
.woocommerce-page .woocommerce-message {
    padding: 20px 20px 20px 50px !important;
    border: 0;
    border-radius: 6px !important;
    background-color: #eff1f3 !important
}

.woocommerce-page .woocommerce-info.payment-message,
.woocommerce-page .woocommerce-message.payment-message {
    width: 100%;
    margin: 20px 0
}

.woocommerce-page .woocommerce-info.payment-message:before,
.woocommerce-page .woocommerce-message.payment-message:before {
    top: inherit;
    margin: inherit;
    border: 0 !important
}

.woocommerce-page .woocommerce-info.payment-message p,
.woocommerce-page .woocommerce-message.payment-message p {
    margin: 0 !important
}

.woocommerce-page .woocommerce-info:after,
.woocommerce-page .woocommerce-message:after {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    width: 30px;
    height: 100%;
    content: '';
    border-radius: 6px 0 0 6px
}

.woocommerce-page .woocommerce-info:before,
.woocommerce-page .woocommerce-message:before {
    line-height: 21px;
    position: absolute;
    z-index: 2;
    top: calc(50% - 11px);
    left: 8px;
    height: 21px;
    color: #fff
}

.woocommerce-page .woocommerce-info li,
.woocommerce-page .woocommerce-message li {
    font-size: 14px;
    line-height: 20px;
    color: rgba(54, 76, 105, .85)
}

.woocommerce-page .woocommerce-info:after,
.woocommerce-page .woocommerce-message:after {
    background-color: #7235fd
}

.woocommerce-page:not(.archive):not(.hemi_online_course) .page-wrapper {
    // padding-top: 68px;
    padding-bottom: 0
}

.woocommerce-page:not(.archive):not(.hemi_online_course) .page-wrapper section:not(.fg-force-fullwidth) .fg-bg-layer {
    background: #f7f7f7 !important
}

.woocommerce-page.archive .products .product .product-wrap .image-wrap {
    padding-bottom: 100%
}

.woocommerce-page.archive .products .product .product-wrap .image-wrap img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.woocommerce-page:not(.archive) .page-wrapper .fg-section:first-child {
    padding-top: 20px;
    padding-bottom: 20px
}

.woocommerce-page:not(.archive) .page-wrapper .fg-section:first-child .fg-wrapper {
    padding-top: 0
}

.woocommerce-page:not(.archive) .page-wrapper .fg-section:first-child .container {
    padding: 0 !important
}

.woocommerce-page:not(.archive) .page-wrapper .ywgc_enter_code label,
.woocommerce-page:not(.archive) .page-wrapper form label {
    font-size: 14px;
    font-weight: 400;
    color: rgba(54, 76, 105, .7)
}

.woocommerce-page:not(.archive) .page-wrapper .ywgc_enter_code .form-row,
.woocommerce-page:not(.archive) .page-wrapper form .form-row {
    margin-bottom: 10px;
    padding: 0
}

.woocommerce-page:not(.archive) .page-wrapper .ywgc_enter_code .form-row .custom-checkbox,
.woocommerce-page:not(.archive) .page-wrapper form .form-row .custom-checkbox {
    margin: 25px 0 10px
}

.woocommerce-page:not(.archive) .page-wrapper .ywgc_enter_code input[type=email],
.woocommerce-page:not(.archive) .page-wrapper .ywgc_enter_code input[type=password],
.woocommerce-page:not(.archive) .page-wrapper .ywgc_enter_code input[type=tel],
.woocommerce-page:not(.archive) .page-wrapper .ywgc_enter_code input[type=text],
.woocommerce-page:not(.archive) .page-wrapper .ywgc_enter_code select,
.woocommerce-page:not(.archive) .page-wrapper form input[type=email],
.woocommerce-page:not(.archive) .page-wrapper form input[type=password],
.woocommerce-page:not(.archive) .page-wrapper form input[type=tel],
.woocommerce-page:not(.archive) .page-wrapper form input[type=text],
.woocommerce-page:not(.archive) .page-wrapper form select {
    font-size: 16px !important;
    font-weight: 500;
    height: 40px !important;
    padding: 6px 10px !important;
    color: #364c69 !important;
    border: solid 1px #e5e5e5 !important;
    border-radius: 2px;
    background: #fff
}

.woocommerce-page:not(.archive) .page-wrapper .ywgc_enter_code textarea,
.woocommerce-page:not(.archive) .page-wrapper form textarea {
    font-size: 16px !important;
    font-weight: 500;
    padding: 0;
    color: #364c69 !important;
    border: solid 1px #e5e5e5 !important;
    border-radius: 2px;
    background: #fff
}

.woocommerce-page:not(.archive) .page-wrapper .ywgc_enter_code fieldset,
.woocommerce-page:not(.archive) .page-wrapper form fieldset {
    margin-top: 30px !important
}

.woocommerce-page:not(.archive) .page-wrapper .ywgc_enter_code fieldset legend,
.woocommerce-page:not(.archive) .page-wrapper form fieldset legend {
    border: 0
}

.woocommerce-account .woocommerce .woocommerce-MyAccount-navigation {
    width: 300px;
    padding: 0 40px 0 0;
    border-right: solid 1px #e5e5e5
}

.woocommerce-account .woocommerce .woocommerce-MyAccount-navigation ul {
    padding: 0
}

.woocommerce-account .woocommerce .woocommerce-MyAccount-navigation ul li {
    height: 40px;
    list-style: none
}

.woocommerce-account .woocommerce .woocommerce-MyAccount-navigation ul li a {
    font-size: 16px;
    font-weight: 400;
    line-height: 40px;
    display: block;
    width: 100%;
    padding: 0 20px;
    text-decoration: none;
    color: #364c69;
    border-radius: 4px
}

.woocommerce-account .woocommerce .woocommerce-MyAccount-navigation ul li.is-active a {
    color: #706e81;
    background-color: rgba(54, 76, 105, .05)
}

.woocommerce-account .woocommerce .woocommerce-MyAccount-content {
    width: calc(100% - 299px);
    margin-left: -1px;
    padding: 0 0 0 40px;
    border-left: solid 1px #e5e5e5
}

.woocommerce-account .woocommerce .woocommerce-MyAccount-content h3 {
    font-size: 21px;
    line-height: 2.29;
    float: none !important;
    margin-bottom: 15px;
    color: #364c69
}

.woocommerce-account .woocommerce .woocommerce-MyAccount-content h3+form h3 {
    margin-top: 25px
}

.woocommerce-account .woocommerce .woocommerce-MyAccount-content input.btn {
    margin-top: 25px
}

.woocommerce-account .woocommerce .woocommerce-MyAccount-content .woocommerce-error,
.woocommerce-account .woocommerce .woocommerce-MyAccount-content .woocommerce-message {
    width: 50%
}

.woocommerce-error,
.woocommerce-message {
    padding: 20px 20px 20px 50px;
    border: 0;
    border-radius: 6px;
    background-color: #eff1f3
}

.woocommerce-error:after,
.woocommerce-message:after {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    width: 30px;
    height: 100%;
    content: '';
    border-radius: 6px 0 0 6px
}

.woocommerce-error:before,
.woocommerce-message:before {
    line-height: 21px;
    position: absolute;
    z-index: 2;
    top: calc(50% - 11px);
    left: 8px;
    height: 21px;
    color: #fff
}

.woocommerce-error li,
.woocommerce-message li {
    font-size: 14px;
    line-height: 20px;
    color: rgba(54, 76, 105, .85)
}

.woocommerce-error:after {
    background-color: #e26a61
}

.empty-message .empty-message-text {
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
    height: 40px;
    margin-bottom: 40px;
    color: rgba(54, 76, 105, .8)
}

.empty-message .empty-message-text>svg {
    float: left;
    margin-right: 30px
}

.empty-message .empty-message-text>svg.downloads-icon {
    margin-top: -7px
}

.empty-message.shopping-cart-menu-content {
    height: 100px;
    margin-bottom: -100px;
    padding: 15px 25px
}

.empty-message.shopping-cart-menu-content .empty-message-text {
    font-size: 18px !important;
    line-height: 70px;
    height: 70px;
    margin-bottom: 0
}

.empty-message.shopping-cart-menu-content .empty-message-text svg {
    height: 68px
}

.auth-form {
    padding: 0;
    border-radius: 4px;
    background: #fff
}

.auth-form .woocommerce-form-row {
    margin: 0 0 12px 0 !important;
    padding: 0 !important
}

.auth-form .btn-fullwidth {
    margin-top: 18px
}

.auth-form label {
    line-height: 1.2 !important
}

.auth-form .tab-pane form {
    margin: 0 !important;
    padding: 40px 40px 20px 40px !important;
    border: 0 !important
}

.auth-form .notice {
    font-size: 13px;
    font-weight: 300;
    margin-top: 22px;
    color: #364c69
}

.auth-form .notice a {
    color: #9081fc
}

ul.products.custom-loop {
    display: -ms-flexbox;
    display: flex;
    padding-top: 10px;
    padding-bottom: 35px;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.columns-6 ul.products.custom-loop li.product {
    -ms-flex-preferred-size: calc(16.5% - 1px);
    flex-basis: calc(16.5% - 1px)
}

.columns-5 ul.products.custom-loop li.product {
    float: none;
    -ms-flex-preferred-size: calc(20% - 1px);
    flex-basis: calc(20% - 1px)
}

.columns-4 ul.products.custom-loop li.product {
    -ms-flex-preferred-size: calc(25% - 1px);
    flex-basis: calc(25% - 1px)
}

.columns-3 ul.products.custom-loop li.product {
    -ms-flex-preferred-size: calc(33% - 1px);
    flex-basis: calc(33% - 1px)
}

.columns-2 ul.products.custom-loop li.product {
    -ms-flex-preferred-size: calc(50% - 1px);
    flex-basis: calc(50% - 1px)
}

.columns-1 ul.products.custom-loop li.product {
    -ms-flex-preferred-size: calc(100% - 1px);
    flex-basis: calc(100% - 1px)
}

ul.products.custom-loop li.product {
    position: relative;
    z-index: 0;
    width: 100% !important;
    height: auto;
    min-height: 320px;
    margin: 0 0 10px 0 !important;
    padding: 22px 30px;
    transition: box-shadow .2s ease-in, background .2s ease-in;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-preferred-size: 228px;
    flex-basis: 228px
}

ul.products.custom-loop li.product.product-category {
    line-height: 54px;
    overflow: hidden;
    height: 56px !important;
    min-height: 56px !important;
    padding: 0 !important;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    background-color: rgba(255, 255, 255, .7);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .02);
    -ms-flex: 0 0 49% !important;
    flex: 0 0 49% !important
}

ul.products.custom-loop li.product.product-category:hover {
    background: #ecedee
}

ul.products.custom-loop li.product.product-category+.type-product {
    margin-right: 50% !important
}

ul.products.custom-loop li.product.product-category+.type-product+.type-product {
    margin-left: -50% !important
}

ul.products.custom-loop li.product.product-category a {
    display: block !important;
    padding: 0 15px !important
}

ul.products.custom-loop li.product.product-category a h2 {
    font-size: 15px !important;
    font-weight: 400;
    line-height: 54px !important;
    float: left;
    overflow: hidden;
    width: calc(100% - 50px);
    padding: 0 !important;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #364c69
}

ul.products.custom-loop li.product.product-category a mark {
    font-size: 15px;
    font-weight: 500;
    float: right;
    width: 35px;
    padding: 0 !important;
    text-align: right;
    color: rgba(54, 76, 105, .51);
    background: 0 0
}

ul.products.custom-loop li.product.product-category:nth-child(even) {
    margin-left: 1.8% !important
}

ul.products.custom-loop li.product.product-category:last-child {
    clear: both
}

ul.products.custom-loop li.product .woocommerce-loop-product__title,
ul.products.custom-loop li.product a h2.woocommerce-loop-product__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
    height: auto;
    margin-bottom: 5px;
    padding: 0;
    color: #364c69;
    -ms-flex-order: 2;
    order: 2
}

ul.products.custom-loop li.product .product-wrap {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 30px
}

ul.products.custom-loop li.product .product-wrap .wcmp-widget-playlist {
    display: none
}

ul.products.custom-loop li.product .product-wrap .attachment-shop_catalog {
    display: none
}

ul.products.custom-loop li.product .product-wrap .mejs-controls button {
    pointer-events: none
}

ul.products.custom-loop li.product .product-wrap .image-wrap {
    background-size: cover;
    background-position: center center
}

ul.products.custom-loop li.product .product-wrap .image-wrap .play-icon {
    position: absolute;
    z-index: 9999;
    font-size: 45px;
    cursor: pointer;
    color: #fff;
    text-shadow: 0 0 10px #000;
    top: 50%;
    left: -50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: .3s all
}

ul.products.custom-loop li.product .product-wrap .image-wrap:hover .play-icon {
    left: 50%
}

ul.products.custom-loop li.product .product-wrap .image-wrap.only-image img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100%;
    width: 100%
}

ul.products.custom-loop li.product .product-wrap .image-wrap.only-image::before {
    content: '';
    width: 100%;
    padding-bottom: 100%;
    display: block !important
}

ul.products.custom-loop li.product:hover {
    z-index: 1
}

ul.products.custom-loop li.product:hover .button {
    display: block
}

ul.products.custom-loop li.product:hover a>span:not(.image-wrap),
ul.products.custom-loop li.product:hover a>span:not(.price) {
    display: block
}

ul.products.custom-loop li.product:hover .product-wrap {
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 12px 23px 0 rgba(97, 75, 146, .03), 0 2px 17px 0 rgba(0, 0, 0, .04)
}

ul.products.custom-loop li.product .product-description {
    display: none
}

ul.products.custom-loop li.product a {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    text-decoration: none
}

ul.products.custom-loop li.product a>span {
    font-size: 14px;
    font-weight: 300;
    display: none;
    text-align: left;
    color: #333;
    -ms-flex-order: 4;
    order: 4
}

ul.products.custom-loop li.product a>span.image-wrap {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    border-radius: 4px;
    -ms-flex-order: 1;
    order: 1
}

ul.products.custom-loop li.product a>span.price {
    font-size: 14px;
    display: block;
    margin: 3px 0 0 0;
    -ms-flex-order: 5;
    order: 5
}

ul.products.custom-loop li.product a>span.price span {
    display: inline-block !important;
    color: #7236fd
}

ul.products.custom-loop li.product a>span.price .regular-price {
    padding: 0 0 0 4px;
    color: #b3bcc7;
    text-decoration: line-through
}

ul.products.custom-loop li.product a>span.price del span {
    font-size: 13px;
    text-decoration: line-through;
    color: #ccc
}

ul.products.custom-loop li.product a>span.price ins span {
    font-size: 15px;
    font-weight: 500;
    text-decoration: none
}

ul.products.custom-loop li.product a img {
    display: none
}

ul.products.custom-loop li.product a .fake-image {
    display: block;
    visibility: hidden;
    width: 100%;
    height: auto;
    pointer-events: none;
    opacity: 0
}

ul.products.custom-loop li.product a .image-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover
}

ul.products.custom-loop li.product a .star-rating {
    height: 18px;
    margin: 0 0 8px 0 !important;
    -ms-flex-order: 3;
    order: 3
}

ul.products.custom-loop li.product .button {
    font-size: 16px;
    font-weight: 500;
    display: none;
    margin: 10px 0 0 0;
    padding: 12px 0;
    text-align: center;
    color: #fff;
    border-radius: 2px;
    background-image: linear-gradient(280deg, #549ff8, #ba6eff);
    box-shadow: 0 7px 33px 0 rgba(130, 77, 191, .19)
}

.woocommerce-order-overview {
    padding-left: 0 !important
}

.order-again {
    margin-bottom: 40px
}

.woocommerce-Address address,
.woocommerce-customer-details address {
    font-size: 15px;
    font-weight: 500;
    line-height: 35px;
    margin: 30px 0;
    color: #364c69
}

.woocommerce-Address address span,
.woocommerce-customer-details address span {
    line-height: 20px;
    display: block;
    max-width: 500px;
    margin-top: -1px;
    padding: 10px 0;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5
}

.woocommerce-Address address .empty-message,
.woocommerce-customer-details address .empty-message {
    height: 100%
}

.woocommerce-Addresses.col2-set .woocommerce-Address {
    width: 46%
}

.woocommerce-customer-details address {
    margin: 0
}

#checkout-shipping .shipping_address,
#checkout-shipping .woocommerce-additional-fields__field-wrapper {
    width: 60%
}

.wc_payment_methods li {
    padding-bottom: 15px
}

.wc_payment_methods li:last-child {
    padding: 0
}

.wc_payment_methods li img {
    padding: 2px 6px !important;
    border-radius: 4px;
    background: #fff
}

.wc_payment_methods li a {
    font-size: 13px;
    font-weight: 400;
    margin-left: 10px;
    text-decoration: none;
    color: #7236fd
}

.sidebar-menu {
    padding-left: 0
}

.sidebar-menu h3 {
    font-size: 24px;
    line-height: 2;
    margin: 0;
    color: #364c69
}

.sidebar-menu ul {
    padding: 0
}

.sidebar-menu ul li {
    list-style: none
}

.sidebar-menu ul li a {
    font-size: 15px;
    line-height: 40px;
    display: block;
    padding: 0 10px;
    text-decoration: none;
    color: #364c69;
    border-radius: 2px
}

.sidebar-menu ul li a:hover {
    background: rgba(54, 76, 105, .05)
}

.sidebar-menu ul li.active {
    background: rgba(54, 76, 105, .05)
}

.sidebar-menu ul li.active:hover {
    cursor: default;
    background: 0 0
}

.categories-list h2 {
    font-size: 24px;
    line-height: 2;
    margin: 0;
    color: #364c69
}

.categories-list .cat-description {
    margin: 0 0 15px;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4;
    color: #34343c
}

.woocommerce-page .pagination {
    display: none
}

.woocommerce-page .pagination .views form {
    float: left;
    margin-left: 10px;
    padding: 5px
}

.group_table {
    max-width: 100%;
    padding-right: 15px
}

.group_table td {
    font-size: 16px;
    font-weight: 400;
    padding-right: 0 !important;
    padding-left: 0 !important;
    text-align: left;
    vertical-align: middle !important
}

.group_table td .quantity {
    margin: 0 !important
}

.group_table td.label {
    padding: 8px 12px !important
}

.group_table td.label label {
    font-size: 16px !important;
    line-height: 20px;
    display: inline-block;
    margin: 0
}

.group_table td.price {
    font-size: 18px !important
}

.promo-toggle {
    line-height: 40px;
    position: relative;
    height: 40px
}

.promo-toggle .coupon-place {
    position: absolute;
    z-index: -1;
    top: 0;
    height: 0;
    padding: 18px 22px 18px 16px;
    transition: all .35s;
    opacity: 0;
    border-radius: 4px;
    background: #eff1f3
}

.promo-toggle .coupon-place label {
    float: right;
    margin: 0
}

.promo-toggle .coupon-place label svg {
    height: 40px;
    cursor: pointer;
    opacity: .7
}

.promo-toggle .coupon-place label:hover svg {
    opacity: 1
}

.promo-toggle .coupon-place .btn {
    margin: 0 25px 0 10px
}

.promo-toggle br {
    display: none
}

.promo-toggle #show-promo {
    position: absolute;
    opacity: 0
}

.promo-toggle #show-promo+label {
    position: relative;
    z-index: 2;
    width: 370px;
    cursor: pointer
}

.promo-toggle #show-promo:checked~.coupon-place {
    position: absolute;
    z-index: 1;
    top: 40px;
    height: 76px;
    opacity: 1
}

.checkout-coupon-wrapper .woocommerce-info {
    margin: 0 0 10px 0 !important;
    padding: 0 !important;
    border: 0 !important;
    background: 0 !important
}

.checkout-coupon-wrapper .woocommerce-info:after,
.checkout-coupon-wrapper .woocommerce-info:before {
    display: none
}

.checkout-coupon-wrapper a {
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: rgba(54, 76, 105, .7)
}

.checkout-coupon-wrapper a:hover {
    color: #364c69
}

.ywgc-have-code {
    line-height: 40px;
    margin-bottom: 10px;
    padding: 0
}

.ywgc-have-code a {
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    color: #7236fd
}

.checkout_coupon,
.ywgc-enter-code {
    width: 350px;
    height: 76px;
    margin: 0 0 20px 0 !important;
    padding: 18px 22px 18px 16px !important;
    transition: all .35s;
    border: 0 !important;
    border-radius: 4px;
    background: #eff1f3 !important
}

.checkout_coupon p,
.ywgc-enter-code p {
    width: auto !important;
    margin-bottom: 0 !important
}

.checkout_coupon p input[type=text],
.ywgc-enter-code p input[type=text] {
    font-size: 16px !important;
    font-weight: 500;
    width: 215px;
    max-width: 215px !important;
    height: 40px !important;
    padding: 6px 10px !important;
    color: #364c69 !important;
    border: solid 1px #e5e5e5 !important;
    border-radius: 2px;
    background: #fff;
    vertical-align: middle
}

.checkout_coupon p input[type=submit],
.ywgc-enter-code p input[type=submit] {
    float: right
}

.checkout_coupon br,
.ywgc-enter-code br {
    display: none
}

.ywgc-enter-code {
    width: auto;
    display: inline-block
}

.woocommerce-page.woocommerce-checkout .woocommerce-checkout-review-order-table td:last-child,
.woocommerce-page.woocommerce-checkout .woocommerce-checkout-review-order-table th:last-child {
    max-width: 320px;
    text-align: right
}

.woocommerce-page.woocommerce-checkout .woocommerce-checkout-review-order-table td:last-child p,
.woocommerce-page.woocommerce-checkout .woocommerce-checkout-review-order-table th:last-child p {
    margin-bottom: 0
}

.woocommerce-page.woocommerce-checkout form.checkout .panel-title br {
    display: none
}

.woocommerce-page.woocommerce-cart .page-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    box-sizing: border-box
}

.woocommerce-page.woocommerce-cart .page-wrapper .hs-main-section {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    box-sizing: border-box
}

.woocommerce-page.woocommerce-cart.admin-bar .page-wrapper {
    min-height: calc(100vh - 32px)
}

@media (max-width:600px) {
    .woocommerce-page.woocommerce-cart .page-wrapper {
        margin-top: 0;
        padding-top: 0 !important
    }
}

.woocommerce .cart-collaterals .cart_totals .shop_table td,
.woocommerce .cart-collaterals .cart_totals .shop_table th {
    width: 50% !important;
    vertical-align: middle
}

.woocommerce .woocommerce-remove-coupon,
.woocommerce .ywgc-remove-gift-card {
    font-size: 0;
    line-height: 0;
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-top: -2px;
    margin-left: 15px;
    vertical-align: middle;
    color: transparent;
    background: url(./images/close-icon.svg) no-repeat center center;
    background-size: cover
}

.woocommerce ul.products li.product .product-wrap .price del {
    display: inline-block;
    opacity: 1
}

.woocommerce ul.products li.product .product-wrap .price ins {
    display: inline-block;
    margin-left: 5px
}

#grouped_form_wrapper,
#review_form_wrapper {
    position: fixed;
    z-index: 100000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    text-align: center;
    background: rgba(0, 0, 0, .4)
}

#grouped_form_wrapper .closemodal,
#review_form_wrapper .closemodal {
    position: absolute;
    top: 30px;
    right: 30px;
    display: block;
    width: 23px;
    height: 23px
}

#grouped_form_wrapper .closemodal svg path,
#review_form_wrapper .closemodal svg path {
    fill: #7235fd
}

#grouped_form_wrapper .closemodal:hover,
#review_form_wrapper .closemodal:hover {
    opacity: .75
}

#grouped_form_wrapper:before,
#review_form_wrapper:before {
    display: inline-block;
    height: 100%;
    margin-right: -4px;
    content: '';
    vertical-align: middle
}

#grouped_form_wrapper #grouped_form,
#grouped_form_wrapper #review_form,
#review_form_wrapper #grouped_form,
#review_form_wrapper #review_form {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 800px;
    padding: 30px 50px 30px 50px;
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    text-align: left;
    vertical-align: middle;
    border: none;
    border-radius: 6px;
    background: #fff
}

#grouped_form_wrapper #grouped_form .required,
#grouped_form_wrapper #review_form .required,
#review_form_wrapper #grouped_form .required,
#review_form_wrapper #review_form .required {
    padding-right: 5px;
    color: #7235fd
}

#grouped_form_wrapper #grouped_form #grouped .btn-lg,
#grouped_form_wrapper #grouped_form #respond .btn-lg,
#grouped_form_wrapper #review_form #grouped .btn-lg,
#grouped_form_wrapper #review_form #respond .btn-lg,
#review_form_wrapper #grouped_form #grouped .btn-lg,
#review_form_wrapper #grouped_form #respond .btn-lg,
#review_form_wrapper #review_form #grouped .btn-lg,
#review_form_wrapper #review_form #respond .btn-lg {
    display: inline-block;
    width: auto !important;
    margin-top: 20px
}

#grouped_form_wrapper #grouped_form #grouped .group_table,
#grouped_form_wrapper #grouped_form #respond .group_table,
#grouped_form_wrapper #review_form #grouped .group_table,
#grouped_form_wrapper #review_form #respond .group_table,
#review_form_wrapper #grouped_form #grouped .group_table,
#review_form_wrapper #grouped_form #respond .group_table,
#review_form_wrapper #review_form #grouped .group_table,
#review_form_wrapper #review_form #respond .group_table {
    width: 100%
}

#grouped_form_wrapper #grouped_form #grouped .group_table .product td,
#grouped_form_wrapper #grouped_form #respond .group_table .product td,
#grouped_form_wrapper #review_form #grouped .group_table .product td,
#grouped_form_wrapper #review_form #respond .group_table .product td,
#review_form_wrapper #grouped_form #grouped .group_table .product td,
#review_form_wrapper #grouped_form #respond .group_table .product td,
#review_form_wrapper #review_form #grouped .group_table .product td,
#review_form_wrapper #review_form #respond .group_table .product td {
    width: 66px;
    border-bottom: 10px solid transparent
}

#grouped_form_wrapper #grouped_form #grouped .group_table .product td input,
#grouped_form_wrapper #grouped_form #respond .group_table .product td input,
#grouped_form_wrapper #review_form #grouped .group_table .product td input,
#grouped_form_wrapper #review_form #respond .group_table .product td input,
#review_form_wrapper #grouped_form #grouped .group_table .product td input,
#review_form_wrapper #grouped_form #respond .group_table .product td input,
#review_form_wrapper #review_form #grouped .group_table .product td input,
#review_form_wrapper #review_form #respond .group_table .product td input {
    font-size: 18px !important;
    height: 46px !important;
    margin: 0 !important;
    border: solid 1px #e5e5e5;
    border-radius: 2px
}

#grouped_form_wrapper #grouped_form #grouped .group_table .product td.label,
#grouped_form_wrapper #grouped_form #respond .group_table .product td.label,
#grouped_form_wrapper #review_form #grouped .group_table .product td.label,
#grouped_form_wrapper #review_form #respond .group_table .product td.label,
#review_form_wrapper #grouped_form #grouped .group_table .product td.label,
#review_form_wrapper #grouped_form #respond .group_table .product td.label,
#review_form_wrapper #review_form #grouped .group_table .product td.label,
#review_form_wrapper #review_form #respond .group_table .product td.label {
    display: table-cell;
    padding: 0 !important
}

#grouped_form_wrapper #grouped_form #grouped .group_table .product td.label label,
#grouped_form_wrapper #grouped_form #respond .group_table .product td.label label,
#grouped_form_wrapper #review_form #grouped .group_table .product td.label label,
#grouped_form_wrapper #review_form #respond .group_table .product td.label label,
#review_form_wrapper #grouped_form #grouped .group_table .product td.label label,
#review_form_wrapper #grouped_form #respond .group_table .product td.label label,
#review_form_wrapper #review_form #grouped .group_table .product td.label label,
#review_form_wrapper #review_form #respond .group_table .product td.label label {
    width: 590px;
    padding-right: 35px !important;
    padding-left: 20px !important
}

#grouped_form_wrapper #grouped_form #grouped .group_table .product td.price,
#grouped_form_wrapper #grouped_form #respond .group_table .product td.price,
#grouped_form_wrapper #review_form #grouped .group_table .product td.price,
#grouped_form_wrapper #review_form #respond .group_table .product td.price,
#review_form_wrapper #grouped_form #grouped .group_table .product td.price,
#review_form_wrapper #grouped_form #respond .group_table .product td.price,
#review_form_wrapper #review_form #grouped .group_table .product td.price,
#review_form_wrapper #review_form #respond .group_table .product td.price {
    position: relative;
    width: 60px;
    text-align: right
}

#grouped_form_wrapper #grouped_form #grouped .group_table .product td.price>.amount,
#grouped_form_wrapper #grouped_form #respond .group_table .product td.price>.amount,
#grouped_form_wrapper #review_form #grouped .group_table .product td.price>.amount,
#grouped_form_wrapper #review_form #respond .group_table .product td.price>.amount,
#review_form_wrapper #grouped_form #grouped .group_table .product td.price>.amount,
#review_form_wrapper #grouped_form #respond .group_table .product td.price>.amount,
#review_form_wrapper #review_form #grouped .group_table .product td.price>.amount,
#review_form_wrapper #review_form #respond .group_table .product td.price>.amount {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 500;
    float: right;
    text-decoration: none;
    color: #425772
}

#grouped_form_wrapper #grouped_form #grouped .group_table .product td.price ins,
#grouped_form_wrapper #grouped_form #respond .group_table .product td.price ins,
#grouped_form_wrapper #review_form #grouped .group_table .product td.price ins,
#grouped_form_wrapper #review_form #respond .group_table .product td.price ins,
#review_form_wrapper #grouped_form #grouped .group_table .product td.price ins,
#review_form_wrapper #grouped_form #respond .group_table .product td.price ins,
#review_form_wrapper #review_form #grouped .group_table .product td.price ins,
#review_form_wrapper #review_form #respond .group_table .product td.price ins {
    font-family: Rubik;
    font-size: 15px;
    font-weight: 500;
    position: absolute;
    right: 0;
    bottom: 0;
    text-decoration: none;
    color: #425772
}

#grouped_form_wrapper #grouped_form #grouped .group_table .product td.price del,
#grouped_form_wrapper #grouped_form #respond .group_table .product td.price del,
#grouped_form_wrapper #review_form #grouped .group_table .product td.price del,
#grouped_form_wrapper #review_form #respond .group_table .product td.price del,
#review_form_wrapper #grouped_form #grouped .group_table .product td.price del,
#review_form_wrapper #grouped_form #respond .group_table .product td.price del,
#review_form_wrapper #review_form #grouped .group_table .product td.price del,
#review_form_wrapper #review_form #respond .group_table .product td.price del {
    position: absolute;
    top: 0;
    right: 0;
    color: rgba(54, 76, 105, .5) !important
}

#grouped_form_wrapper #grouped_form #grouped .group_table .product td.price del span,
#grouped_form_wrapper #grouped_form #respond .group_table .product td.price del span,
#grouped_form_wrapper #review_form #grouped .group_table .product td.price del span,
#grouped_form_wrapper #review_form #respond .group_table .product td.price del span,
#review_form_wrapper #grouped_form #grouped .group_table .product td.price del span,
#review_form_wrapper #grouped_form #respond .group_table .product td.price del span,
#review_form_wrapper #review_form #grouped .group_table .product td.price del span,
#review_form_wrapper #review_form #respond .group_table .product td.price del span {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    color: rgba(54, 76, 105, .5) !important
}

#grouped_form_wrapper #grouped_form #grouped p,
#grouped_form_wrapper #grouped_form #respond p,
#grouped_form_wrapper #review_form #grouped p,
#grouped_form_wrapper #review_form #respond p,
#review_form_wrapper #grouped_form #grouped p,
#review_form_wrapper #grouped_form #respond p,
#review_form_wrapper #review_form #grouped p,
#review_form_wrapper #review_form #respond p {
    font-size: 14px !important;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 15px;
    letter-spacing: -.09px;
    color: rgba(54, 76, 105, .7)
}

#grouped_form_wrapper #grouped_form #grouped p input,
#grouped_form_wrapper #grouped_form #respond p input,
#grouped_form_wrapper #review_form #grouped p input,
#grouped_form_wrapper #review_form #respond p input,
#review_form_wrapper #grouped_form #grouped p input,
#review_form_wrapper #grouped_form #respond p input,
#review_form_wrapper #review_form #grouped p input,
#review_form_wrapper #review_form #respond p input {
    width: 100%;
    max-width: 100%
}

#grouped_form_wrapper #grouped_form #grouped p label,
#grouped_form_wrapper #grouped_form #respond p label,
#grouped_form_wrapper #review_form #grouped p label,
#grouped_form_wrapper #review_form #respond p label,
#review_form_wrapper #grouped_form #grouped p label,
#review_form_wrapper #grouped_form #respond p label,
#review_form_wrapper #review_form #grouped p label,
#review_form_wrapper #review_form #respond p label {
    font-size: 14px;
    line-height: 24px;
    display: block;
    letter-spacing: -.09px;
    color: rgba(54, 76, 105, .7)
}

#grouped_form_wrapper #grouped_form #grouped #reply-title,
#grouped_form_wrapper #grouped_form #respond #reply-title,
#grouped_form_wrapper #review_form #grouped #reply-title,
#grouped_form_wrapper #review_form #respond #reply-title,
#review_form_wrapper #grouped_form #grouped #reply-title,
#review_form_wrapper #grouped_form #respond #reply-title,
#review_form_wrapper #review_form #grouped #reply-title,
#review_form_wrapper #review_form #respond #reply-title {
    font-size: 21px;
    display: block;
    margin-bottom: 20px;
    text-align: left;
    letter-spacing: 0;
    color: #364c69
}

#grouped_form_wrapper #grouped_form #grouped .comment-notes,
#grouped_form_wrapper #grouped_form #respond .comment-notes,
#grouped_form_wrapper #review_form #grouped .comment-notes,
#grouped_form_wrapper #review_form #respond .comment-notes,
#review_form_wrapper #grouped_form #grouped .comment-notes,
#review_form_wrapper #grouped_form #respond .comment-notes,
#review_form_wrapper #review_form #grouped .comment-notes,
#review_form_wrapper #review_form #respond .comment-notes {
    display: none
}

#grouped_form_wrapper #grouped_form #grouped .comment-form-comment-title,
#grouped_form_wrapper #grouped_form #respond .comment-form-comment-title,
#grouped_form_wrapper #review_form #grouped .comment-form-comment-title,
#grouped_form_wrapper #review_form #respond .comment-form-comment-title,
#review_form_wrapper #grouped_form #grouped .comment-form-comment-title,
#review_form_wrapper #grouped_form #respond .comment-form-comment-title,
#review_form_wrapper #review_form #grouped .comment-form-comment-title,
#review_form_wrapper #review_form #respond .comment-form-comment-title {
    float: left;
    width: 50%;
    padding-right: 15px
}

#grouped_form_wrapper #grouped_form #grouped .comment-form-comment,
#grouped_form_wrapper #grouped_form #respond .comment-form-comment,
#grouped_form_wrapper #review_form #grouped .comment-form-comment,
#grouped_form_wrapper #review_form #respond .comment-form-comment,
#review_form_wrapper #grouped_form #grouped .comment-form-comment,
#review_form_wrapper #grouped_form #respond .comment-form-comment,
#review_form_wrapper #review_form #grouped .comment-form-comment,
#review_form_wrapper #review_form #respond .comment-form-comment {
    clear: both;
    width: 100%
}

#grouped_form_wrapper #grouped_form #grouped .comment-form-comment textarea,
#grouped_form_wrapper #grouped_form #respond .comment-form-comment textarea,
#grouped_form_wrapper #review_form #grouped .comment-form-comment textarea,
#grouped_form_wrapper #review_form #respond .comment-form-comment textarea,
#review_form_wrapper #grouped_form #grouped .comment-form-comment textarea,
#review_form_wrapper #grouped_form #respond .comment-form-comment textarea,
#review_form_wrapper #review_form #grouped .comment-form-comment textarea,
#review_form_wrapper #review_form #respond .comment-form-comment textarea {
    max-width: 100%;
    resize: none
}

#grouped_form_wrapper #grouped_form #grouped .comment-form-author,
#grouped_form_wrapper #grouped_form #respond .comment-form-author,
#grouped_form_wrapper #review_form #grouped .comment-form-author,
#grouped_form_wrapper #review_form #respond .comment-form-author,
#review_form_wrapper #grouped_form #grouped .comment-form-author,
#review_form_wrapper #grouped_form #respond .comment-form-author,
#review_form_wrapper #review_form #grouped .comment-form-author,
#review_form_wrapper #review_form #respond .comment-form-author {
    float: left;
    width: 50%;
    margin-bottom: 20px;
    padding-right: 15px
}

#grouped_form_wrapper #grouped_form #grouped .comment-form-email,
#grouped_form_wrapper #grouped_form #respond .comment-form-email,
#grouped_form_wrapper #review_form #grouped .comment-form-email,
#grouped_form_wrapper #review_form #respond .comment-form-email,
#review_form_wrapper #grouped_form #grouped .comment-form-email,
#review_form_wrapper #grouped_form #respond .comment-form-email,
#review_form_wrapper #review_form #grouped .comment-form-email,
#review_form_wrapper #review_form #respond .comment-form-email {
    float: left;
    width: 50%;
    margin-bottom: 20px;
    padding-left: 15px
}

#grouped_form_wrapper #grouped_form #grouped .form-submit,
#grouped_form_wrapper #grouped_form #respond .form-submit,
#grouped_form_wrapper #review_form #grouped .form-submit,
#grouped_form_wrapper #review_form #respond .form-submit,
#review_form_wrapper #grouped_form #grouped .form-submit,
#review_form_wrapper #grouped_form #respond .form-submit,
#review_form_wrapper #review_form #grouped .form-submit,
#review_form_wrapper #review_form #respond .form-submit {
    clear: both;
    width: 100%;
    margin: 0
}

#grouped_form_wrapper #grouped_form #grouped .form-submit input#submit,
#grouped_form_wrapper #grouped_form #respond .form-submit input#submit,
#grouped_form_wrapper #review_form #grouped .form-submit input#submit,
#grouped_form_wrapper #review_form #respond .form-submit input#submit,
#review_form_wrapper #grouped_form #grouped .form-submit input#submit,
#review_form_wrapper #grouped_form #respond .form-submit input#submit,
#review_form_wrapper #review_form #grouped .form-submit input#submit,
#review_form_wrapper #review_form #respond .form-submit input#submit {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px !important;
    width: auto;
    height: 40px;
    padding: 8px 20px;
    letter-spacing: 0;
    color: #fff !important;
    border: none !important;
    background-image: linear-gradient(rgba(0, 0, 0, .01), rgba(0, 0, 0, .01)), linear-gradient(285deg, #549ff8, #ba6eff);
    box-shadow: 0 7px 33px 0 rgba(130, 77, 191, .19)
}

#grouped_form_wrapper #grouped_form #grouped .form-submit input#submit:focus,
#grouped_form_wrapper #grouped_form #grouped .form-submit input#submit:hover,
#grouped_form_wrapper #grouped_form #respond .form-submit input#submit:focus,
#grouped_form_wrapper #grouped_form #respond .form-submit input#submit:hover,
#grouped_form_wrapper #review_form #grouped .form-submit input#submit:focus,
#grouped_form_wrapper #review_form #grouped .form-submit input#submit:hover,
#grouped_form_wrapper #review_form #respond .form-submit input#submit:focus,
#grouped_form_wrapper #review_form #respond .form-submit input#submit:hover,
#review_form_wrapper #grouped_form #grouped .form-submit input#submit:focus,
#review_form_wrapper #grouped_form #grouped .form-submit input#submit:hover,
#review_form_wrapper #grouped_form #respond .form-submit input#submit:focus,
#review_form_wrapper #grouped_form #respond .form-submit input#submit:hover,
#review_form_wrapper #review_form #grouped .form-submit input#submit:focus,
#review_form_wrapper #review_form #grouped .form-submit input#submit:hover,
#review_form_wrapper #review_form #respond .form-submit input#submit:focus,
#review_form_wrapper #review_form #respond .form-submit input#submit:hover {
    outline: 0;
    background-image: linear-gradient(rgba(255, 255, 255, .2), rgba(255, 255, 255, .2)), linear-gradient(rgba(0, 0, 0, .01), rgba(0, 0, 0, .01)), linear-gradient(285deg, #549ff8, #ba6eff);
    box-shadow: 0 7px 33px 0 rgba(130, 77, 191, .19)
}

.global-login {
    width: 375px;
    margin: 0 auto 40px !important;
    padding: 40px !important;
    background: #fff
}

.global-login .woocommerce-Button {
    margin-top: 20px
}

/* News related */
.page-template-news .custom_banner_wraper .left_product_img.image {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center
}

.page-template-news .custom_banner_wraper .left_product_img.image img {
    width: auto;
    height: 100%;
    max-width: 100%;
    max-height: 250px
}

/* Single Post related */
.single-post-wrapper {
    padding-top: 0
}

.single-post-wrapper * {
    font-family: Rubik, Arial, sans-serif !important
}

.single-post-wrapper li,
.single-post-wrapper p {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -.1px;
    color: rgba(54, 76, 105, .7)
}

.single-post-wrapper h1,
.single-post-wrapper h2,
.single-post-wrapper h3,
.single-post-wrapper h4,
.single-post-wrapper h5,
.single-post-wrapper h6 {
    color: rgba(54, 76, 105, .7)
}

.single-post-wrapper address {
    color: rgba(54, 76, 105, .7)
}

.single-post-wrapper pre {
    color: rgba(54, 76, 105, .7);
    font-size: 16px
}

.single-post-wrapper dl {
    color: rgba(54, 76, 105, .7)
}

.single-post-wrapper table {
    color: rgba(54, 76, 105, .7)
}

.single-post-wrapper caption {
    color: rgba(54, 76, 105, .7)
}

.single-post-wrapper legend {
    color: rgba(54, 76, 105, .7)
}

.single-post-wrapper label {
    color: rgba(54, 76, 105, .7)
}

.single-post-wrapper input {
    color: rgba(54, 76, 105, .7);
    border-color: rgba(54, 76, 105, .7)
}

.single-post-wrapper textarea {
    border-color: rgba(54, 76, 105, .7)
}

.single-post-wrapper iframe {
    width: 100%;
    height: auto;
}

/* Gift Card related */

.product-type-gift-card .woocommerce-product-gallery__wrapper {
  margin-bottom: 45px;
  box-shadow: none;
}

.product-type-gift-card
  .woocommerce-product-gallery__wrapper
  .woocommerce-product-gallery__image {
  display: inline-block;
  max-width: 310px;
  max-height: none;
  min-height: 0;
  width: 100%;
  height: auto;
  margin: 5px auto 0;
  border-radius: 6px;
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19),
    0 11px 22px 0 rgba(0, 0, 0, 0.12);
  padding: 0;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .ywgc-main-form-preview-container {
  display: none;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .product_meta {
  margin: 28px 0 35px;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .product-summary.border-top
  p {
  padding-top: 0;
  border-top: none;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form {
  padding-top: 15px;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-template {
  display: none;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .gift-cards-list {
  margin-bottom: 15px;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .gift-cards-list
  .ywgc-amount-label
  label {
  display: inline-block;
  margin-bottom: 5px;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .gift-cards-list
  .ywgc-amount-value
  h3 {
  display: none;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .gift-cards-list
  button {
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  padding: 6px 10px;
  color: #364c69;
  border: solid 1px #e5e5e5;
  border-radius: 2px;
  background: #fff;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .gift-cards-list
  button.selected_button {
  color: #fff;
  background-image: linear-gradient(286deg, #549ff8, #ba6eff);
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator {
  padding-top: 0;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-sender-info-title,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc_recipient_info_title,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-sender-info-title,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc_recipient_info_title {
  margin-top: 2em;
  font-weight: 500;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-editor-section-title,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-editor-section-title {
  padding: 30px 0;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: -0.1px;
  text-transform: capitalize;
  color: #364c69;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-single-recipient,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-single-recipient {
  margin-bottom: 20px;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-message,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient-email,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient-name,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-sender-name,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-message,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient-email,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient-name,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-sender-name {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-message
  label,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient-email
  label,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient-name
  label,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-sender-name
  label,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-message
  label,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient-email
  label,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient-name
  label,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-sender-name
  label {
  display: none;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-sender-name,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-sender-name {
  margin-bottom: 15px;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient-name
  input[type="text"],
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient-name
  input[type="text"] {
  display: block;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-message,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-message {
  display: block;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-message
  textarea,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-message
  textarea {
  width: 100%;
  float: none;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient-name
  input[type="text"],
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-sender-name
  input[type="text"],
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  textarea,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient-name
  input[type="text"],
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-sender-name
  input[type="text"],
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  textarea {
  width: 276px;
  float: none;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient-name
  input[type="text"]::-webkit-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient::-webkit-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-sender-name
  input[type="text"]::-webkit-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  textarea::-webkit-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient-name
  input[type="text"]::-webkit-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient::-webkit-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-sender-name
  input[type="text"]::-webkit-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  textarea::-webkit-input-placeholder {
  font-size: 16px !important;
  color: #364c69 !important;
  text-transform: inherit !important;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient-name
  input[type="text"]::-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient::-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-sender-name
  input[type="text"]::-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  textarea::-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient-name
  input[type="text"]::-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient::-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-sender-name
  input[type="text"]::-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  textarea::-moz-placeholder {
  font-size: 16px !important;
  color: #364c69 !important;
  text-transform: inherit !important;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient-name
  input[type="text"]:-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient:-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-sender-name
  input[type="text"]:-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  textarea:-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient-name
  input[type="text"]:-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient:-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-sender-name
  input[type="text"]:-moz-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  textarea:-moz-placeholder {
  font-size: 16px !important;
  color: #364c69 !important;
  text-transform: inherit !important;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient-name
  input[type="text"]:-ms-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient:-ms-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-sender-name
  input[type="text"]:-ms-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  textarea:-ms-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient-name
  input[type="text"]:-ms-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient:-ms-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-sender-name
  input[type="text"]:-ms-input-placeholder,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  textarea:-ms-input-placeholder {
  font-size: 16px !important;
  color: #364c69 !important;
  text-transform: inherit !important;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .ywgc-recipient-email
  .ywgc-recipient,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-recipient-email
  .ywgc-recipient {
  width: auto;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  a.add-recipient,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  a.add-recipient {
  position: relative;
  display: inline-block;
  margin: 0 0 10px;
  padding-left: 30px;
  padding-right: 5px;
  line-height: 21px;
  color: #8d84fc;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  a.add-recipient::before,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  a.add-recipient::before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  background: url(./images/add-icon.svg) no-repeat center center;
  background-size: cover;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  a.add-recipient:hover,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  a.add-recipient:hover {
  text-decoration: underline;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  a.ywgc-remove-recipient,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  a.ywgc-remove-recipient {
  position: absolute;
  display: block;
  top: 12px;
  right: 5px;
  width: 15px;
  height: 15px;
  font-size: 0;
  color: transparent;
  background: url(./images/close-icon.svg) no-repeat center center;
  background-size: cover;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  textarea,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  textarea {
  min-height: 106px;
  padding: 6px 10px;
  resize: none;
  box-sizing: border-box;
  display: block;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .gift-card-content-editor.step-content,
.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .ywgc-generator
  .gift-card-content-editor.step-content {
  margin-bottom: 20px;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .gift_card_template_button {
  margin-top: 0;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .gift_card_template_button
  .quantity {
  float: left;
  margin-right: 20px;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .gift_card_template_button
  .quantity
  input[type="number"] {
  height: 56px;
  font-size: 18px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .gift_card_template_button
  .ywgc-multi-recipients {
  margin: 5px 0 16px;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .gift_card_template_button
  .single_add_to_cart_button {
  display: inline-block;
  float: none;
  height: 56px;
  padding: 16px 25px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #fff;
  background-image: linear-gradient(286deg, #549ff8, #ba6eff);
  box-shadow: 0 7px 33px 0 rgba(130, 77, 191, 0.19);
  border-radius: 2px;
  box-sizing: border-box;
}

.product-type-gift-card
  .product-summary-section
  .product-summary
  .gift-cards_form
  .gift_card_template_button::after {
  display: table;
  content: "";
  clear: both;
}

/* Online Course Related */
.hemi_online_course .woocommerce-breadcrumb {
  display: none;
}

.hemi_online_course .related.products .products {
  margin-left: -15px;
  margin-right: -15px;
}

.hemi_online_course .related.products .products.custom-loop {
  margin-bottom: 0;
}

.hemi_online_course .related.products .products.custom-loop .product {
  -ms-flex-preferred-size: 33%;
  flex-basis: 33%;
}

.hemi_online_course
  .related.products
  .products.custom-loop
  .product
  .product-wrap {
  position: static;
  padding: 0;
  margin-bottom: 40px;
  background: 0 0;
  box-shadow: none;
}

.hemi_online_course
  .related.products
  .products.custom-loop
  .product
  .product-wrap
  .image-wrap {
  margin-bottom: 20px;
}

.hemi_online_course
  .related.products
  .products.custom-loop
  .product
  .product-wrap
  .image-wrap:after {
  content: "";
  display: inline-block;
  width: 100%;
  padding-bottom: 61.5%;
}

.hemi_online_course .related.products .product {
  padding: 22px 15px;
}

.hemi_online_course .related.products .product .woocommerce-Price-amount {
  font-size: 15px;
  font-weight: 500;
}

.hemi_online_course .related.products .product .related-course-author {
  margin-bottom: 12px;
  color: #7236fd;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-transform: uppercase;
  -ms-flex-order: 2;
  order: 2;
}

.hemi_online_course
  .related.products
  .product
  .woocommerce-loop-product__title {
  color: #364c69;
  font-size: 24px;
  line-height: 31px;
  font-weight: 400;
  margin-bottom: 8px;
}

.hemi_online_course .related-header {
  display: block;
  text-align: center;
}

.hemi_online_course .related-header h3 {
  color: #364c69;
  font-size: 26px;
  line-height: 48px;
  font-weight: 400;
  text-align: center;
}

.product-view-course {
  margin-top: 25px;
}

.product-view-course .product-summary {
  margin-top: -5px;
  margin-bottom: 100px;
}

.product-view-course .product-summary .product_title.entry-title {
  display: none;
}

.product-view-course .product-summary .fg-paragraph {
  color: rgba(54, 76, 105, 0.8);
  font-size: 16px;
  line-height: 20px;
}

.product-view-course .product-summary .fg-paragraph h3 {
  color: #364c69;
  font-size: 24px;
  line-height: 48px;
}

.product-view-course .product-summary > p {
  color: rgba(54, 76, 105, 0.8);
  line-height: 20px;
}

.product-view-course .woocommerce-product-details__short-description {
  color: rgba(54, 76, 104, 0.8);
}

.product-view-course .woocommerce-product-details__short-description p {
  color: inherit;
  line-height: inherit;
  margin-bottom: 25px;
}

.product-view-course .woocommerce-product-details__short-description h3 {
  color: #364c69;
}

.product-view-course .product-price-wrapper {
  margin: 10px 0 50px;
}

.product-view-course .product-price-wrapper .cart {
  text-align: left;
}

.product-view-course .product-price-wrapper .for-audio,
.product-view-course .product-price-wrapper .posted_in {
  display: none !important;
}

.product-view-course .product-price-wrapper .btn,
.product-view-course .product-price-wrapper .quick_buy_container {
  display: inline-block;
}

.product-view-course .product-price-wrapper .btn {
  margin-right: 20px;
}

.product-view-course .product-share-section .share-all-wrapper {
  width: 74px;
}

.product-view-course .product-share-section .share-all-wrapper .share-title {
  float: none;
  margin: 0 0 18px;
  text-align: center;
}

.product-view-course .product-share-section .share-all-wrapper .addtoany_list {
  text-align: center;
}

.product-view-course
  .product-share-section
  .share-all-wrapper
  .addtoany_list
  > a {
  margin-bottom: 8px;
}

.product-view-course
  .product-share-section
  .share-all-wrapper
  .addtoany_list
  > a
  > span {
  width: 48px;
  height: 48px;
  line-height: 48px;
  padding: 9px;
}

@media (max-width: 1200px) {
  .product-view-course .product-share-section .share-all-wrapper {
    width: auto;
  }

  .product-view-course .product-share-section .share-all-wrapper .addtoany_list,
  .product-view-course .product-share-section .share-all-wrapper .share-title {
    text-align: left;
  }

  .product-view-course .product-share-section .share-all-wrapper .share-title {
    margin-bottom: 3px;
  }

  .product-view-course
    .product-share-section
    .share-all-wrapper
    .addtoany_shortcode {
    margin-left: -5px;
  }
}

.product-view-course .meta-single-post-wrap {
  max-width: 200px;
  padding: 38px 0 0;
  margin-left: auto;
}

@media (max-width: 1200px) {
  .product-view-course .meta-single-post-wrap {
    margin-left: 0;
    margin-bottom: 90px;
  }
}

.product-view-course .course-trainer-info {
  text-align: center;
}

.product-view-course .course-trainer-info .course-trainer-image {
  border-radius: 300px;
  width: 120px;
  height: 120px;
  -o-object-fit: cover;
  object-fit: cover;
}

.product-view-course .course-trainer-info .course-trainer-title-wrapper {
  margin-top: 23px;
  padding: 13px 15px;
  border-top: 1px solid #e5e5e5;
}

.product-view-course
  .course-trainer-info
  .course-trainer-title-wrapper
  .course-trainer-label {
  color: #364c69;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.1px;
  line-height: 24px;
}

.product-view-course
  .course-trainer-info
  .course-trainer-title-wrapper
  .course-trainer-title {
  color: #364c69;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: 24px;
  margin-bottom: 0;
}

.hemi-course-list {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}

.hemi-course-list > .hemi-course-item {
  float: left;
  width: calc(50% - 30px);
}

.hemi-course-list .hemi-course-item {
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 7px;
}

.hemi-course-list .hemi-course-item-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 22px 28px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-decoration: none;
}

.hemi-course-list .hemi-course-item-link .hemi-course-item-top {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.hemi-course-list .hemi-course-item:after {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 55%;
  background-color: #eaeaea;
  border-radius: 6px;
  min-height: 265px;
}

.hemi-course-list .hemi-course-item:hover .hemi-lesson-media-video-icon {
  -ms-transform: scale(1.15);
  transform: scale(1.15);
}

.hemi-course-list .hemi-course-item-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 7px;
  -webkit-filter: blur(4px);
  filter: blur(4px);
}

.hemi-course-list .hemi-course-item-bg:after,
.hemi-course-list .hemi-course-item-bg:before {
  content: "";
  position: absolute;
  left: -1px;
  top: 0;
  width: 66%;
  height: 100%;
  z-index: 1;
  border-radius: 6px;
}

.hemi-course-list .hemi-course-item-bg:before {
  width: 78%;
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
  background: linear-gradient(
    288.44deg,
    #eaeaea 0,
    rgba(235, 235, 235, 0.96) 10.53%,
    rgba(238, 238, 238, 0.86) 34.03%,
    rgba(241, 241, 241, 0) 85%
  );
}

.hemi-course-list .hemi-course-item-bg:after {
  left: auto;
  right: 0;
  background: linear-gradient(
    288.44deg,
    #eaeaea 0,
    rgba(235, 235, 235, 0.96) 10.53%,
    rgba(238, 238, 238, 0.86) 34.03%,
    rgba(241, 241, 241, 0) 70%
  );
}

.hemi-course-list .course-price {
  padding: 4px 5px;
  background-color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  border-radius: 2px;
}

.hemi-course-list .course-price .course-regular-price {
  padding: 0 4px;
  color: #b3bcc7;
  text-decoration: line-through;
}

.hemi-course-list .course-price .course-regular-price:only-child {
  color: #7236fd;
  text-decoration: none;
}

.hemi-course-list .course-price .course-sale-price {
  padding: 0 4px;
  color: #7236fd;
}

.hemi-course-list .course-price .course-currency-symbol {
  padding-right: 2px;
}

.hemi-course-list .hemi-course-level {
  display: inline-block;
  border-radius: 2px;
  padding: 4px 9px;
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 11px;
}

.hemi-course-list .hemi-course-includes {
  color: rgba(0, 0, 0, 0.35);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.hemi-course-list .hemi-course-icons {
  text-align: center;
  margin-bottom: 10px;
  margin-top: -10px;
}

.hemi-course-list .hemi-course-icons .hemi-lesson-media-video-icon {
  width: 123px;
  height: 123px;
  margin-top: -30px;
  margin-bottom: -35px;
  -webkit-filter: drop-shadow(0 9px 21px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 9px 21px rgba(0, 0, 0, 0.1));
  transition: transform 0.3s;
}

.hemi-course-list .hemi-course-author,
.hemi-course-list .hemi-course-title {
  color: #364c69;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 4.85px;
  line-height: 28px;
  text-transform: uppercase;
  text-align: center;
}

.hemi-course-list .hemi-course-title {
  margin-bottom: 8px;
}

@media (max-width: 1200px) {
  .hemi-course-list .hemi-course-title {
    font-size: 18px;
    line-height: 24px;
  }
}

.hemi-course-list .hemi-course-author {
  font-size: 14px;
  letter-spacing: 2.04px;
  line-height: 17px;
  margin-bottom: 5px;
}

@media (max-width: 1200px) {
  .hemi-course-list .hemi-course-author {
    letter-spacing: normal;
  }
}

.hemi-course-list:after {
  content: "";
  display: table;
  clear: both;
}

.research-paper-slider .caption-wrap.caption-top-align {
  padding-top: 95px;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.ff-custom-slider-2.kill-my-top-padding {
  padding-top: 0 !important;
}

.ffb-id-27aildea.give-me-right-pad {
  padding-right: 30px;
}

.ffb-id-27aildea.give-me-right-pad .categories-toggle {
  text-align: left;
  margin-bottom: 35px;
}

.ffb-id-1crjfmgq.carousel-fade .carousel-control {
  z-index: 2;
  border: 0;
  padding: 0;
}

.category-sidebar-menu {
  width: 20%;
  z-index: 3;
}

.category-sidebar-menu.inactive {
  opacity: 0.4;
  pointer-events: none;
}

.categories-list {
  width: 80%;
}

.categories-list .products-preloader {
  display: block;
  min-height: 400px;
  background-color: rgba(255, 255, 255, 0.95);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;
  z-index: 1;
}

.categories-list .products-preloader .preloader-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.mob-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.store-home-sidebar {
  padding-top: 0;
  padding-left: 0;
  @media (max-width: 992px) {
    padding-top: 15px;
    position: fixed;
    padding-left: 15px;
    top: 68px;
    background-color: #f7f7f7;
    left: -320px;
    height: calc(100vh - 68px);
    overflow: scroll;
    width: 320px;
    transition: .3s all;
    &.expanded {
      left: 0;
      padding: 15px;
    }
  }
  .back-breadcrumbs {
    li {
      &.current-category {
        margin-left: 24px;
        color: #8d8d8d;
        margin-top: 10px;
      }
      a {
        color: #5093e2;
        i {
          display: inline-block;
          font-size: 12px;
          font-weight: 300;
          margin-right: 10px;
        }
      }
    }
  }
  p {
    font-weight: 700;
  }
  ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 40px;
    &.ul-child {
      li {
        display: flex;
        cursor: pointer;
        &[disabling="0"] {
          display: none;
        }

        .false-check {
          margin-top: 2px;
          width: 17px;
          min-width: 17px;
          height: 17px;
          background-color: #fff;
          border: 2px solid #eeeff9;
          border-radius: 3px;
          display: block;
          margin-right: 6px;
          i.showme {
            display: flex;
          }
        }

        .category-name {
          display: block;
        }
      }
    }
    li {
      margin-bottom: 5px;
      i {
        display: none;
      }
    }
  }
}

.logged-in .store-home-sidebar {
  @media (max-width: 768px) {
    top: 114px;
  }
  @media (max-width: 992px) {
    top: 100px;
  }
}

.category-right-wrapper {
  padding-left: 30px;
  padding-right: 9px;
}

.category-right-wrapper .controls {
  display: none;
}

.category-right-wrapper .category-content-head {
  position: relative;
  z-index: 2;
  border-bottom: 2px solid #e5e5e5;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.category-right-wrapper .category-content-head .col-md-6 {
  padding-left: 0;
  padding-right: 0;
}

.category-right-wrapper .category-content-head h2 {
  font-weight: 400;
  left: 32px;
}

.category-right-wrapper .category-content-head .cat-description {
  font-size: 16px;
  margin-bottom: 30px;
}

.category-right-wrapper
  .category-content-head
  .category-filters-wrapper
  .sort-text {
  margin-right: 10px;
}

.category-right-wrapper
  .category-content-head
  .category-filters-wrapper
  #category-filters {
  display: none;
}

.category-right-wrapper
  .category-content-head
  .category-filters-wrapper
  .select2-container
  .selection {
  margin-right: 0;
}

.category-right-wrapper
  .category-content-head
  .category-filters-wrapper
  .select2-container
  .selection
  .select2-selection {
  margin-right: 0;
  border-radius: 4px;
}

.category-right-wrapper
  .category-content-head
  .category-filters-wrapper
  .select2-container
  .selection
  .select2-selection
  .select2-selection__rendered {
  font-size: 14px;
  font-weight: 400;
  margin-right: 0;
}

.category-right-wrapper
  .category-content-head
  .category-filters-wrapper
  .select2-container
  .selection
  .select2-selection
  .select2-selection__arrow {
  margin-right: 0;
  margin-left: 10px;
  position: relative;
  top: auto;
  right: auto;
  height: auto;
}

.category-right-wrapper .custom-loop.ajaxed .product:not([data-group="1"]) {
  display: none;
}

.category-right-wrapper .ajax-pagination {
  margin-top: 70px;
  text-align: center;
}

.category-right-wrapper .ajax-pagination a.page-numbers.active {
  color: #7235fd;
  pointer-events: none;
}

.category-right-wrapper .ajax-pagination a.page-numbers.load-more {
  width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.tax-product_cat .select2-container {
  z-index: 3333;
  margin-top: 40px;
}

@media (max-width: 776px) {
  .tax-product_cat .select2-container {
    margin-top: 53px;
  }
}

.tax-product_cat .select2-container .select2-dropdown {
  padding: 0;
  border: none;
}

.tax-product_cat
  .select2-container
  .select2-dropdown
  .select2-search
  .select2-search__field {
  display: none;
}

.tax-product_cat
  .select2-container
  .select2-dropdown
  .select2-results
  .select2-results__options {
  padding-left: 0;
}

.tax-product_cat
  .select2-container
  .select2-dropdown
  .select2-results
  .select2-results__options
  .select2-results__option {
  list-style-type: none;
  font-size: 14px;
  padding: 6px 18px;
  background-color: #fff;
  color: #364c69;
  cursor: pointer;
}

.tax-product_cat
  .select2-container
  .select2-dropdown
  .select2-results
  .select2-results__options
  .select2-results__option[aria-selected="true"] {
  font-weight: 500;
  color: #7236fd;
}

.tax-product_cat
  .select2-container
  .select2-dropdown
  .select2-results
  .select2-results__options
  .select2-results__option:hover {
  color: #7236fd;
}

.tax-product_cat .product:hover .play-lesson,
.tax-product_cat .product:hover .play-sample {
  display: block;
}

.tax-product_cat .product .play-lesson,
.tax-product_cat .product .play-sample {
  display: none;
}

.tax-product_cat .product .play-lesson.active .show-default,
.tax-product_cat .product .play-sample.active .show-default {
  display: none;
}

.tax-product_cat .product .play-lesson.active .show-active,
.tax-product_cat .product .play-sample.active .show-active {
  display: block;
}

.tax-product_cat .product .play-lesson svg,
.tax-product_cat .product .play-sample svg {
  -ms-transform: scale(0.5) translateX(-50%);
  transform: scale(0.5) translateX(-50%);
  margin-right: -10px;
}

.tax-product_cat .product .play-lesson svg + span,
.tax-product_cat .product .play-sample svg + span {
  font-size: 14px;
  color: #7236fd !important;
}

.tax-product_cat .product .wcmp-player {
  display: none;
}

.tax-product_cat .wcmp-player-container {
  display: block;
}

.category-count {
  color: #bebebe;
}

.expire-action-btn .genre-shop-now {
  color: #fff;
  font-size: 22px !important;
  margin-bottom: 0;
  transition: 0.5s all;
  position: absolute;
  bottom: 6px;
  left: 30px;
  transition: 0.5s all;
  opacity: 0;
}

.expire-action-btn .genre-shop-now i {
  line-height: 38px;
  font-size: 12px;
  margin-left: 10px;
}

.expire-action-btn:hover .genre-title {
  -ms-transform: translateY(-25px);
  transform: translateY(-25px);
}

.expire-action-btn:hover .genre-shop-now {
  opacity: 1;
}

#content .woocommerce-breadcrumb {
  display: none;
}

.related.products > h2 {
  display: none;
}

.related.products .custom-loop {
  padding-bottom: 0;
}

.related.products .custom-loop .product .product-wrap {
  padding: 20px;
}

.related.products .custom-loop .product .product-wrap .audio-info,
.related.products .custom-loop .product .product-wrap .mejs-audio,
.related.products .custom-loop .product .product-wrap .play-icon {
  display: none;
}

.related-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mejs-controls {
  z-index: 0 !important;
}

.page-footer.zindexed {
  z-index: 0;
}

.product-summary-section {
  margin-left: 50%;
}

.single-product {
  overflow: visible;
}

.single-product .product {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.single-product .product-summary-section {
  margin-left: unset;
}

.single-product .product-summary-section .share-title {
  font-weight: 300;
}

.single-product .product-image-section {
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
}

.single-product .product-image-section h3 {
  font-weight: 300;
  color: #34343c;
  line-height: 1.4;
  margin: 0 0 15px;
  text-align: center;
  font-size: 18px;
}

.single-product .product-image-section h3 a {
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0 20px 0;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #6f6f6f;
}

@media (max-height: 850px) {
  .single-product .product-image-section {
    top: 50px;
  }
}

.single-product.stuck {
  position: fixed;
  width: 570px;
  bottom: 0;
}

.single-product.stuck-top {
  position: fixed;
  width: 570px;
}

.single-product.absoluted {
  position: absolute;
  bottom: 0;
}

.single-product .wcmp-player-title {
  display: none;
}

.single-product .tab_main_content .hemi-lesson-player-container h2 {
  color: #333 !important;
}

.single-product .tab_main_content .hemi-lesson-player-container span {
  color: rgba(0, 0, 0, 0.5) !important;
}

.single-product .wrapper-of-active {
  position: relative;
  z-index: 20;
}

.single-product #grouped_form_wrapper #grouped_form #grouped .product td.label {
  width: unset !important;
}

@media (max-width: 992px) {
  .single-product .product {
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .single-product .product-image-section {
    top: unset;
  }
}

@media (max-width: 480px) {
  .single-product .product-image-section .radio-container {
    margin-top: 8px;
  }
}
.course_content {
  background-color: #374b6a;
  padding-bottom: 80px;
  border-bottom: 1px solid #23334e;
}

.course_content .video_section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 14px;
  margin-bottom: 86px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.course_content .video_section .blue_area {
  width: 33.6%;
  background-color: #002a62;
  color: #fff;
  padding: 28px 15px 28px 35px;
}

.course_content .video_section .blue_area a,
.course_content .video_section .blue_area h6 {
  margin: 0;
  font-size: 16px;
  color: #c3ddff;
  font-weight: 400;
  font-family: Rubik, sans-serif;
  text-decoration: none;
}

.course_content .video_section .blue_area h3 {
  font-size: 16px !important;
  color: #f0f7ff;
  font-weight: 400;
  font-family: Rubik, sans-serif;
  margin: 0 0 11px;
}

.course_content .video_section .blue_area ul {
  margin: 0;
  padding: 0 0 16px 19px;
}

.course_content .video_section h5 {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  font-family: Rubik, sans-serif;
  margin: 0;
}

.course_content .video_section .custom_video {
  width: 66%;
}

.course_content .video_section iframe {
  width: 100%;
  display: block;
  height: 100%;
  min-height: 313px;
}

.course_content .video_section li,
.course_content .video_section p {
  font-size: 16px !important;
  color: #f0f7ff;
  font-weight: 400;
  font-family: Rubik, sans-serif;
  margin: 0 0 11px;
}

.course_content .video_section li {
  margin-bottom: 0;
}

.course_content .video_section .counter-number-wrapper span {
  color: #fff !important;
  font-size: 28px !important;
}

.course_tabe .tabs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.course_tabe .tabs .tab_left_side {
  width: 29%;
}

.course_tabe .tabs .tab_right_side {
  width: 67%;
  margin-left: 4%;
}

.course_tabe .tabs .tab_right_side ul {
  margin: 0;
  padding-left: 20px;
}

.course_tabe .tabs .tab_right_side ul li {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  font-family: Rubik, sans-serif;
  line-height: 23px;
  margin-bottom: 4px;
  margin-top: 0;
}

.course_tabe .tabs p {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  font-family: Rubik, sans-serif;
  line-height: 23px;
  margin-bottom: 22px;
  margin-top: 0;
}

.course_tabe ul.tabs-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.course_tabe ul.tabs-list li {
  margin-bottom: 20px;
}

.course_tabe ul.tabs-list li.active a {
  background: transparent linear-gradient(103deg, #b470fd 0, #5b9cf8 100%) 0 0
    no-repeat padding-box;
  border-color: #374b6a;
}

.course_tabe ul.tabs-list li a {
  letter-spacing: 0.2px;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Rubik, sans-serif;
  display: block;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 20px 38px;
  position: relative;
  font-weight: 400;
}

.course_tabe ul.tabs-list li a:hover {
  background: transparent linear-gradient(103deg, #b470fd 0, #5b9cf8 100%) 0 0
    no-repeat padding-box;
  border-color: #374b6a;
}

.course_tabe ul.tabs-list li a :after {
  content: "";
  width: 13px;
  height: 24px;
  display: inline-block;
  background-size: 13px;
  position: absolute;
  right: 22px;
  top: 23px;
}

.course_tabe .tab {
  display: none;
}

.course_tabe .tab.active {
  display: block;
}

.course_tabe .under_tab_section {
  background: #002a62 0 0 no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 14px;
  padding: 27px 85px;
  margin-top: 45px;
}

.course_tabe .under_tab_section a,
.course_tabe .under_tab_section p {
  font-size: 20px !important;
  font-weight: 700;
  font-family: Rubik, sans-serif;
  text-align: center;
  color: #fff;
  line-height: 29px;
}

.course_tabe .under_tab_section a:hover {
  text-decoration: underline;
}

.hemi_online_course .fg-container {
  width: 100%;
}

.hemi_online_course .header-with-titlebar {
  opacity: 0 !important;
  visibility: hidden !important;
  height: 0 !important;
  width: 0 !important;
  position: absolute;
  z-index: 0;
}

.showlesscontent .view_content {
  display: none;
}

.video_section .showmore_btn {
  margin: 12px 0 0;
  font-size: 16px;
  color: #c3ddff;
  font-weight: 400;
  font-family: Rubik, sans-serif;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.video_section .showmore_btn:hover {
  text-decoration: underline;
}

.hemi_online_course .ffb-id-ppn0qml.fg-wrapper.fg-text-dark.has-fg-bg {
  margin-bottom: 0;
  padding-bottom: 0;
}

.hemi_online_course
  section.ffb-id-ppmsqj5.fg-section.hs-product-section.fg-text-dark.has-fg-bg.fg-hover {
  margin-bottom: 0;
  padding-bottom: 0;
}

.post_section .post_content .news_row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #fff;
  box-shadow: 0 2px 14px #d8d9ffaa;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 32px;
  text-align: left;
}

.post_content #response {
  text-align: center;
}

.post_section .post_content .news_img {
  width: 33%;
  position: relative;
}

.news_text_info {
  width: 63%;
  padding: 17px 24px 17px 24px;
}

.post_section .post_content .news_img img {
  width: 100%;
  display: block;
  height: 100% !important;
  -o-object-fit: cover;
  object-fit: cover;
}

.news_text_info a:hover {
  text-decoration: none;
}

.right_side_fillter ul {
  margin: 0;
  padding: 0;
}

form#filter {
  margin-top: 24px;
  text-align: right;
}

label.filter_btns {
  font-size: 14px;
  color: #8475de;
  text-decoration: none;
  border: 1px solid #8475de;
  border-radius: 4px;
  padding: 6px 14px 5px;
  display: inline-block;
  text-align: center;
  margin-left: 1.5%;
  margin-bottom: 12px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
}

label.filter_btns.active,
label.filter_btns:hover {
  background-color: #8475de;
  color: #fff;
}

label.filter_btns:nth-child(4) {
  margin-left: 0;
}

.loader_container img {
  width: 100%;
}

.loader_div {
  position: fixed;
  z-index: 99999999;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  right: 0;
}

.loader_container {
  margin: 0 auto;
  max-width: 100px;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% - 50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  overflow: auto;
  text-align: center;
}

label.filter_btns.featured,
label.filter_btns.main-featured-post {
  display: none;
}

.countdown-period {
  display: block;
}

.countdown-descr {
  display: block;
  width: 100%;
}

.shopping-cart svg {
  display: inline;
  vertical-align: unset;
}