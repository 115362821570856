.tab_main_content .course-accordion .panel .panel-collapse {
  margin-left: 0;
}

.course_content {
  border-bottom: 0px solid #23334e;
}

.hemi_online_course section.related.products {
  display: none;
}

.hemi_online_course .wp-next-post-navi {
  display: none;
}

.tab_main_content .product_meta,
.tab_main_content .product_meta a {
  color: #fff;
}

.tab_main_content .course-accordion .panel .panel-body,
.tab_main_content p,
.tab_main_content span {
  color: #fff !important;
}

.tab_main_content .hemi-lesson .hemi-lesson-title {
  color: #fff;
}

.tab_main_content .hemi-lesson-info-label {
  color: #fff !important;
}

.tab_main_content .hemi-lesson-info-value {
  color: #fff !important;
}

.hemi_online_course .under_tab_section.buy_bluediv {
  max-width: 1160px;
  margin: 0px auto 117px;
  position: relative;
  clear: both;
  transform: translate(0px, 44px);
}

.hemi_online_course .page-footer {
  border-top: 1px solid #23334e;
}

.tab_main_content .hemi-lesson .hemi-lesson-index:after {
  color: #fff;
}

.hemi_online_course .newtabs ul {
  margin: 0;
  padding: 0 0 0 17px;
}

.hemi_online_course .newtabs li {
  color: #fff;
}

.newtabs .course-accordion .panel .panel-body {
  padding-bottom: 0;
}

.newtabs {
  display: flex;
  justify-content: space-between;
}

.hemi_online_course .page-footer {
  padding-top: 50px;
  padding-bottom: 20px;
}

.newtabs .hemi-course-lesson-wrapper {
  margin-bottom: 33px;
}

.newtabs .panel-body > div > p {
  display: none;
}

.tab_main_content
  .product-summary.border-top
  p.ffb-id-3ngs2slt.fg-paragraph.text-left.fg-text-dark {
  margin-top: 0;
  padding-top: 18px;
}

.hemi_online_course .woocommerce-product-rating {
  display: none;
}

.hemi_online_course .video_section .blue_area .hemi-counter {
  width: 100%;
  display: inline-table;
  max-width: 131px;
}

.hemi_online_course #content p,
.hemi_online_course #content li,
.hemi_online_course #content ul,
.hemi_online_course #content span,
.hemi_online_course #content strong {
  color: #fff;
}

.hemi_online_course #content a {
  color: #fff;
}

.page-id-25222 .ffb-id-23ut6onb.fg-wrapper.fg-text-dark.has-fg-bg.fg-hover {
  padding: 0;
}

@media (min-width: 1200px) {
  .hemi_online_course .ffb-id-1chh1nc7 {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 1300px) {
  .category_section .slider_wrap {
    padding: 15px 45px 0;
  }

  .category_section .four_box .left_box h5 {
    font-size: 15px;
  }
}

@media screen and (max-width: 1250px) {
  .category_carousel .carousel_wraper {
    margin: 0 34px;
  }

  .category_carousel .expire-action .cate_title h5 {
    margin: 0;
    font-size: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .category_carousel .cate_title {
    width: 67%;
  }

  .small_container {
    max-width: 100%;
    margin: 0 auto;
    width: 96%;
  }

  .course_content .video_section p,
  .course_content .video_section li,
  .course_content .video_section .blue_area h3 {
    font-size: 14px !important;
  }

  .caf-filter-layout .caf-mb-4 a {
    font-size: 13px !important;
  }

  label.filter_btns {
    padding: 6px 8px 5px;
  }

  .category_section .cate_icon {
    width: 21%;
  }

  .category_section .four_box .left_box .cate_title {
    width: 71%;
  }

  .hemi_online_course .under_tab_section.buy_bluediv {
    width: 96%;
  }
}

@media screen and (max-width: 1100px) {
  .category_carousel .cate_title {
    width: 62%;
  }

  .category_carousel .expire-action .cate_title h5 {
    font-size: 12px;
  }

  .category_carousel a.expire-action {
    height: 63px;
  }

  .category_carousel span.ff-owl-controls-item-prev,
  .category_carousel span.ff-owl-controls-item-next {
    top: 23px;
  }

  .heading_text li a,
  .heading_text li {
    font-size: 18px;
  }

  .post_section .news_text_info h4 a,
  .post_section .news_text_info h2 {
    font-size: 23px;
    line-height: 27px;
  }

  .post_section .news_text_info p {
    font-size: 15px !important;
  }

  .course_content .video_section .blue_area {
    padding: 28px 15px 28px 15px;
  }

  .course_content .video_section h5 {
    font-size: 17px;
  }

  .course_tabe .tabs p,
  .course_tabe .tabs .tab_right_side ul li {
    font-size: 15px !important;
    line-height: 21px;
    margin-bottom: 15px;
  }

  .course_tabe ul.tabs-list li a {
    font-size: 16px;
  }

  .under_tab_section {
    padding: 27px 35px;
  }

  #caf-post-layout-container #caf-filter-layout1 {
    max-width: 474px;
  }

  .caf-filter-layout .caf-mb-4 a {
    padding: 6px 22px 5px;
  }

  .post_content article .caf-post-title h2 {
    font-size: 23px !important;
    line-height: 26px !important;
  }

  .post_content article .caf-content {
    font-size: 15px !important;
    line-height: 19px;
  }

  .post_section .news_text_info h4 a,
  .post_section .news_text_info h2 {
    font-size: 23px;
  }

  label.filter_btns {
    font-size: 12px;
    padding: 6px 7px 5px;
  }

  .custom_banner_wraper .left_product_img img {
    height: 456px;
  }

  .category_section .four_box .left_box .cate_title {
    width: 69%;
  }

  .tab_main_content h1.product_title {
    font-size: 28px;
  }
}

@media screen and (max-width: 1000px) {
  .post_section .search_bar .input_search {
    width: 100%;
  }

  .post_section .news_text_info h4 a,
  .post_section .news_text_info h2 {
    font-size: 20px;
  }

  .post_section span.small_title {
    display: block;
    font-size: 17px;
  }

  .post_section .news_text_info p {
    font-size: 13px !important;
    margin: 13px 0 17px;
  }

  .post_section .post_content .news_img img {
    height: 181px;
  }

  .news_text_info {
    width: 67%;
  }

  .post_section .post_content .news_row {
    margin-bottom: 22px;
  }

  .course_content .video_section p,
  .course_content .video_section li,
  .course_content .video_section .blue_area h3 {
    font-size: 13px !important;
  }

  .course_content .video_section .blue_area h6,
  .course_content .video_section .blue_area a {
    font-size: 15px;
  }

  .under_tab_section p,
  .under_tab_section a {
    font-size: 17px !important;
    line-height: 27px;
  }

  .course_content .video_section {
    margin-bottom: 60px;
  }

  .heading_text li a,
  .heading_text li {
    font-size: 16px;
  }

  .post_content article .caf-post-title h2 {
    font-size: 19px !important;
    line-height: 24px !important;
  }

  .post_content article#caf-post-layout1 #manage-post-area {
    width: 67%;
    padding: 11px 17px 5px 11px;
  }

  .post_content article .caf-content {
    font-size: 14px !important;
    line-height: 17px;
  }

  .post_section .post_content .news_row {
    margin-bottom: 22px;
  }

  .news_text_info {
    width: 67%;
  }

  .post_section .news_text_info h4 a,
  .post_section .news_text_info h2 {
    font-size: 20px;
  }
}

@media screen and (max-width: 991px) {
  .home_carousel .fg-container {
    padding: 0 5% !important;
  }

  .category_carousel .explore_heading {
    margin-bottom: 40px;
  }

  .ark-header-mobile-fixed {
    z-index: 90001;
  }
}

@media screen and (max-width: 900px) {
  .heading_text li a,
  .heading_text li {
    font-size: 16px;
  }

  .post_section .news_text_info h4 a,
  .post_section .news_text_info h2 {
    font-size: 18px;
  }

  .post_section span.small_title {
    display: block;
    font-size: 15px;
  }

  .post_section .news_text_info p {
    font-size: 13px !important;
    margin: 7px 0 12px;
  }

  .post_section .top_wrapper .left_side p {
    font-size: 14px !important;
  }

  .course_content .video_section .blue_area {
    padding: 18px 15px 18px 15px;
  }

  .course_content .video_section p,
  .course_content .video_section li,
  .course_content .video_section .blue_area h3 {
    font-size: 12px !important;
  }

  .course_tabe .tabs p,
  .course_tabe .tabs .tab_right_side ul li {
    font-size: 14px !important;
    margin-bottom: 12px;
  }

  .course_tabe ul.tabs-list li a {
    font-size: 14px;
  }

  .course_tabe ul.tabs-list li a:after {
    background-size: 10px;
  }

  .under_tab_section p,
  .under_tab_section a {
    font-size: 15px !important;
    line-height: 25px;
  }

  .under_tab_section {
    padding: 14px 25px;
  }

  .right_side_fillter ul {
    margin: 0;
    padding: 0;
  }

  .caf-filter-layout .caf-mb-4 a {
    padding: 6px 15px 5px;
    font-size: 12px !important;
  }

  #caf-post-layout-container #caf-filter-layout1 {
    max-width: 100%;
    width: 54%;
  }

  .post_content article .caf-meta-content span {
    font-size: 11px !important;
  }

  .post_content article .caf-content {
    font-size: 13px !important;
    line-height: 17px;
    padding: 6px 0 0 !important;
  }

  .post_content article .caf-content-read-more a.caf-read-more {
    max-width: 89px;
    font-size: 12px !important;
  }

  ul#caf-layout-pagination li a,
  ul#caf-layout-pagination li span {
    width: 34px !important;
    height: 34px !important;
    line-height: 34px !important;
    font-size: 14px !important;
  }

  ul#caf-layout-pagination {
    margin-top: 20px !important;
  }

  .post_section .news_text_info h4 a,
  .post_section .news_text_info h2 {
    font-size: 18px;
  }

  label.filter_btns {
    font-size: 11px;
    padding: 6px 6px 5px;
  }

  .post_section .news_text_info h4 {
    line-height: normal;
  }

  .post_section .news_text_info h4 a,
  .post_section .news_text_info h2 {
    line-height: 22px;
  }

  .custom_banner_wraper .impprovment {
    margin-bottom: 8px;
    font-size: 11px;
  }

  .custom_banner_wraper .left_product_img img {
    height: 399px;
  }

  .category_section .four_box .left_box a {
    height: 64px;
  }

  .category_section .four_box .left_box h5 {
    font-size: 13px;
  }
}

@media screen and (max-width: 800px) {
  .heading_text li a,
  .heading_text li {
    font-size: 15px;
  }

  .news_text_info {
    padding: 17px 13px 17px 13px;
  }

  .caf-filter-layout .caf-mb-4 a {
    padding: 6px 13px 5px;
  }

  .post_content article .caf-post-title h2 {
    font-size: 17px !important;
    line-height: 21px !important;
  }

  .post_content article .caf-content {
    font-size: 12px !important;
    line-height: 14px;
    padding: 6px 0 0 !important;
  }

  .news_text_info {
    padding: 17px 13px 17px 13px;
  }

  .category_section .owl-prev,
  .category_section .owl-next {
    top: 39px;
  }

  .category_section .four_box .left_box h5 {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .home_carousel .fg-container {
    padding: 0 10% !important;
  }

  .category_carousel span.ff-owl-controls-item-prev,
  .category_carousel span.ff-owl-controls-item-next {
    top: 35px;
  }

  .category_carousel .explore_heading {
    margin-bottom: 20px;
  }

  .category_carousel .explore_heading p br {
    display: none;
  }

  .category_carousel .explore_heading p {
    font-size: 13px !important;
    margin-top: 0;
  }

  .post_section .top_wrapper .left_side {
    float: none;
    width: 100%;
  }

  .right_side_fillter {
    float: none;
    width: 100%;
    margin-top: 45px;
  }

  .post_section .post_content {
    width: 100%;
    float: none;
    margin-left: 0;
    padding-bottom: 50px;
  }

  .course_content {
    padding-top: 12px;
  }

  .course_content .video_section .blue_area {
    width: 100%;
  }

  .course_content .video_section .custom_video {
    width: 100%;
  }

  .course_content .video_section p,
  .course_content .video_section li,
  .course_content .video_section .blue_area h3 {
    font-size: 14px !important;
  }

  .course_content .video_section iframe {
    height: 330px;
  }

  #caf-post-layout-container #caf-filter-layout1 {
    max-width: 100%;
    position: unset;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 24px;
  }

  .post_content article .caf-post-title h2 {
    font-size: 19px !important;
    line-height: 24px !important;
  }

  .post_content article .caf-content {
    font-size: 14px !important;
    line-height: 21px;
  }

  .custom_banner_wraper .right_product_info h3 {
    font-size: 12px;
  }

  .heading_text li a,
  .heading_text li {
    font-size: 18px;
  }

  .custom_banner_wraper .enroll_button {
    width: 100%;
    max-width: 340px;
  }

  .category_section .same_heading p br {
    display: none;
  }

  .category_section .cate_icon {
    margin-right: 10%;
  }

  .course_tabe .tabs h3 {
    font-size: 17px;
  }

  .tab_main_content .course-accordion .panel .panel-body {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 650px) {
  .custom_banner_wraper .left_product_img {
    float: none;
    margin-right: 0;
    width: 100%;
    position: relative;
  }

  .custom_banner_wraper .right_product_info {
    float: none;
    width: 100%;
  }

  .course_tabe .tabs p,
  .course_tabe .tabs .tab_right_side ul li {
    font-size: 12px !important;
    margin-bottom: 10px;
    line-height: 18px;
  }

  .course_tabe ul.tabs-list li a:after {
    right: 5px;
  }

  .course_tabe ul.tabs-list li a {
    padding: 20px 20px 20px 12px;
  }

  .under_tab_section p,
  .under_tab_section a {
    font-size: 13px !important;
    line-height: 21px;
  }

  .post_content article .manage-layout1 a {
    width: 37%;
  }

  .post_content article#caf-post-layout1 #manage-post-area {
    width: 63%;
  }

  .post_content article .caf-content {
    line-height: 18px;
  }

  .tab_main_content h1.product_title {
    font-size: 24px;
  }

  .course-accordion .panel .panel-body p,
  .tab_main_content .product_meta,
  .tab_main_content .product_meta a {
    font-size: 14px !important;
  }

  .tab_main_content .hemi-lesson .hemi-lesson-media-container {
    width: 100%;
    margin-right: 0;
    margin-bottom: 14px;
  }

  .tab_main_content .hemi-lesson .hemi-lesson-media-container img {
    width: 100%;
    max-height: inherit;
  }

  .tab_main_content .course-accordion .panel .panel-body {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .hemi_online_course .video_section .blue_area .hemi-counter {
    width: 23%;
    display: inline-table;
    max-width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .post_section .news_text_info h4 a,
  .post_section .news_text_info h2 {
    font-size: 17px;
  }

  .post_section span.small_title {
    display: block;
    font-size: 14px;
  }

  .post_section .news_text_info p {
    font-size: 12px !important;
    margin: 7px 0 12px;
  }

  .news_text_info {
    padding: 8px 13px 12px 13px;
  }

  .course_content .video_section p,
  .course_content .video_section li,
  .course_content .video_section .blue_area h3 {
    font-size: 13px !important;
  }

  .course_content .video_section iframe {
    height: 230px;
  }

  .course_tabe ul.tabs-list li a {
    padding: 15px 20px 14px 12px;
    font-size: 13px;
  }

  .course_tabe ul.tabs-list li a:after {
    right: 5px;
    background-size: 7px;
    width: 7px;
    height: 13px;
    top: 17px;
  }

  .post_content article#caf-post-layout1 .caf-featured-img-box {
    height: 300px;
  }

  .post_content article .manage-layout1 a {
    width: 100%;
  }

  .post_content article#caf-post-layout1 #manage-post-area {
    width: 100%;
    padding: 21px 27px 15px 21px;
  }

  .post_content article {
    max-width: 350px;
    padding: 0;
    margin: 0 auto;
  }

  .news_text_info {
    padding: 8px 13px 12px 13px;
  }

  .post_section .news_text_info h4 a,
  .post_section .news_text_info h2 {
    font-size: 17px;
  }

  .heading_text li a,
  .heading_text li {
    font-size: 16px;
  }

  .tab_main_content h1.product_title {
    font-size: 21px;
  }

  .course-accordion .panel .panel-body p,
  .tab_main_content .product_meta,
  .tab_main_content .product_meta a {
    font-size: 13px !important;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 450px) {
  .category_carousel a.expire-action {
    height: 100px;
    display: table-cell;
    vertical-align: middle;
    padding: 0 6px;
  }

  .category_carousel .icon_box {
    height: 100px;
    background-color: #fff;
    display: table;
    width: 100%;
  }

  .category_carousel .cate_icon {
    margin: 0 auto;
  }

  .category_carousel .cate_title {
    width: 100%;
    text-align: center;
  }

  .category_carousel span.ff-owl-controls-item-prev,
  .category_carousel span.ff-owl-controls-item-next {
    top: 55px;
  }

  .post_section .top_wrapper h3 {
    font-size: 29px;
  }

  .heading_text li a,
  .heading_text li {
    font-size: 13px;
  }

  .heading_text li {
    width: 67%;
  }

  .heading_text li:last-child {
    width: 31%;
  }

  .heading_text li a,
  .heading_text li {
    font-size: 14px;
  }

  .post_section .post_content .news_img {
    width: 100%;
    position: relative;
  }

  .news_text_info {
    padding: 16px 16px 24px 13px;
    width: 100%;
  }

  .course_content .video_section p,
  .course_content .video_section li,
  .course_content .video_section .blue_area h3 {
    font-size: 12px !important;
  }

  .course_content .video_section h5 {
    font-size: 15px;
  }

  .course_content .video_section .blue_area h6,
  .course_content .video_section .blue_area a {
    font-size: 13px;
  }

  .course_content .video_section iframe {
    height: 160px;
  }

  .course_content .video_section {
    margin-bottom: 41px;
  }

  .under_tab_section {
    padding: 14px 17px;
    margin-top: 25px;
  }

  .under_tab_section p,
  .under_tab_section a {
    font-size: 12px !important;
    line-height: 19px;
  }

  .course_tabe .tabs .tab_left_side {
    width: 37%;
  }

  .course_tabe .tabs .tab_right_side {
    width: 59%;
    margin-left: 4%;
  }

  .course_tabe .tabs .tab_left_side {
    width: 37%;
  }

  .course_tabe .tabs p,
  .course_tabe .tabs .tab_right_side ul li {
    font-size: 11px !important;
    margin-bottom: 8px;
    line-height: 17px;
  }

  .course_tabe ul.tabs-list li a {
    font-size: 12px;
  }

  .course_tabe ul.tabs-list li a:after {
    top: 16px;
  }

  .course_tabe ul.tabs-list li {
    margin-bottom: 12px;
  }

  .caf-filter-layout .caf-mb-4 a {
    padding: 5px 10px 4px;
    font-size: 10px !important;
  }

  post_content article .caf-post-title h2 {
    font-size: 18px !important;
    line-height: 22px !important;
  }

  .post_content article#caf-post-layout1 #manage-post-area {
    width: 100%;
    padding: 18px 14px 9px 14px;
  }

  .post_content article .caf-content {
    line-height: 18px;
    font-size: 13px !important;
  }

  .post_content article .caf-meta-content span.comment {
    width: 20%;
  }

  .post_content article .caf-meta-content span {
    font-size: 11px !important;
    width: 43%;
  }

  .post_section .post_content {
    padding-bottom: 20px;
  }

  ul#caf-layout-pagination {
    margin-top: 5px !important;
  }

  .post_section .post_content .news_img {
    width: 100%;
    position: relative;
  }

  .news_text_info {
    padding: 16px 16px 24px 13px;
    width: 100%;
  }

  .category_section .same_heading h4 {
    text-align: center;
    font-size: 29px;
  }

  .category_section .same_heading p {
    font-size: 13px !important;
  }

  .category_section .slider_wrap {
    padding: 0px 45px 0;
  }

  .category_section .cate_icon {
    margin-right: 3%;
  }

  .category_section .cate_icon {
    width: 17%;
  }

  .category_section .four_box .left_box h5 {
    font-size: 15px;
  }

  .wp-next-post-navi-next button,
  .wp-next-post-navi-pre button {
    margin-bottom: 4px;
  }

  .wp-next-post-navi-pre,
  .wp-next-post-navi-next {
    width: 100%;
  }

  .wp-next-post-navi-next {
    margin-bottom: 40px;
  }

  .course_tabe .tabs h3 {
    font-size: 15px;
    line-height: 20px;
  }

  .tab_main_content .course-accordion .panel .panel-body {
    padding: 0;
  }

  .tab_main_content .course-accordion .btn.btn-md {
    padding: 8px 10px;
  }
}

.single-product
  section.ffb-id-2bqrmho8.fg-section.fg-text-dark.has-fg-bg.fg-hover {
  padding: 0 !important;
}

.btn.left-icon span.btn-text img {
  line-height: 40px;
  margin-right: 16px;
  object-fit: contain;
  filter: invert(1);
  order: -5;
}

.woocommerce-checkout .StripeElement {
  width: 100%;
}

.grecaptcha-badge {
  z-index: 1000;
}

.header.navbar-fixed-top .nav-collapse,
.header.navbar-fixed-top-boxed .nav-collapse {
  max-height: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 767px) {
  .header .navbar > .container > .nav-collapse {
    margin-left: 0;
    margin-right: 0;
  }

  .header-transparent .navbar > .container > .nav-collapse {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .header-transparent.navbar-fixed-top .nav-collapse,
  .header-transparent.navbar-fixed-top-boxed .nav-collapse {
    max-height: none;
    padding: 0;
    margin: 0;
  }
}
